export const orderStatusList = [
  {
    "id": 29,
    "name": "Đang trên máy bay Sydney - Việt Nam",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 28,
    "name": "Đã xử lý xong tại trung tâm cơ sở phân loại hàng hoá  Sydney",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 27,
    "name": "Hoàn tất thông quan tại hải quan Sydney",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 26,
    "name": "Đã nhận hàng từ khách",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 25,
    "name": "Tình trạng hải quan đã cập nhật | Sydney |",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 24,
    "name": "Đã đến trung tâm cơ sở phân loại hàng hoá Sydney | Mascot |",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 23,
    "name": "Chờ nhận hàng từ khách",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 21,
    "name": "Đang xử lí đơn hàng tại Sydney",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 20,
    "name": "Đang vận chuyển ra sân bay (Sydney)",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 19,
    "name": "Đang thông quan (Việt Nam)",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 18,
    "name": "Đang xử lí đơn hàng tại kho Việt Nam",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 15,
    "name": "Đang giao hàng đến người nhận",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 11,
    "name": "Đã giao hàng cho người nhận",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 7,
    "name": "Đã hủy đơn hàng",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 5,
    "name": "Giao không thành công lần 1",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 1,
    "name": "Chờ xác nhận lại",
    "language_code": "vi",
    "deleted_at": null
  }
].map((item) => ({ Index: item.id, Title: item.name })).reverse();

export const languageList = [
  {
    "id": 4,
    "code": "vi",
    "name": "Tiếng Việt",
    "priority": 1,
    "flag_icon": "https://dashboard.epispost.com//cms/images/assets/no-img_50x50.png",
    "vi_name": null,
    "created_at": null,
    "updated_at": "2020-07-21T13:22:43.000000Z",
    "deleted_at": null
  }
].map((item) => ({
  id: item.id,
  value: item.code,
  label: item.name
}));

export const statusList = [
  {
    id: '1',
    value: 'active',
    label: 'Active'
  },
  {
    id: '2',
    value: 'de_active',
    label: 'DeActive'
  }
]

export const carrierList = [
  {
    "id": 1,
    "name": "Giao hàng nhanh",
    "deleted_at": null
  }
]
