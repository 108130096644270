import { createSlice } from '@reduxjs/toolkit';

export const namespace = 'app';

const initialState = {
  device: 3 // 3: desktop, 2: tablet, 1: mobile
};

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setDevice: (state, action) => ({
      ...state,
      device: action.payload
    })
  }
});

// Action creators are generated for each case reducer function
export const { setDevice } = slice.actions;
// Selector
export const appSelector = (state) => state[namespace];
// Reducer
export const { reducer } = slice;

export { rootSagas as saga } from './saga';
