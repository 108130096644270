export default `query getRoleList($limit: Int, $offset: Int, $keyword: String $status: StatusType){
  getRoleList(limit: $limit, offset: $offset, keyword: $keyword status: $status) {
    count
    data {
      id
      name
      display_name
      description
    }
  }
}`;

export const createRoleRedux = `
  mutation createRole($name: String! $display_name: String! $description: String $permission_ids: [Int]!) {
    createRole(name: $name, display_name: $display_name, description: $description, permission_ids: $permission_ids) {
      id
    }
  }
`;
