export const COOKIE_TOKEN_NAME = "token";

export const statusList = [
  {
    id: "0",
    value: "active",
    label: "Active",
  },
  {
    id: "1",
    value: "deactive",
    label: "DeActive",
  },
];

export const roleList = [
  {
    id: "0",
    value: "",
    label: "All",
  },
  {
    id: "1",
    value: "1",
    label: "Admin",
  },
  {
    id: "2",
    value: "2",
    label: "Member",
  },
];

export const currencyList = [
  { id: "1", value: "VND", label: "Việt Nam Đồng" },
  { id: "2", value: "AUD", label: "Đô la Úc" },
];
export const deliveryList = [
  { id: "1", value: "1", label: "Express" },
  { id: "2", value: "2", label: "Standard" },
];
