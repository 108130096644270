import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  FormControl,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import {
  permissionListSelector,
  getList as getAllPermission,
} from "src/state/permission/reducer";

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "left",
    padding: "1.25rem",
    boxShadow: "0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
    },
  },
}));

export default function RoleForm({
  args: { touched, values, errors, handleChange, handleBlur },
  dataRole,
}) {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <FormControl classes={{ root: classes.form }}>
        <Typography
          variant="h6"
          color="default"
          classes={{ root: classes.typo }}
        >
          Phân quyền
        </Typography>
        <TextField
          error={Boolean(touched.name && errors.name)}
          fullWidth
          helperText={touched.name && errors.name}
          label="Role name"
          margin="normal"
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.name}
          variant="outlined"
        />

        <TextField
          error={Boolean(touched.display_name && errors.display_name)}
          fullWidth
          helperText={touched.display_name && errors.display_name}
          label="Display name"
          margin="normal"
          name="display_name"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.display_name}
          variant="outlined"
        />

        <TextField
          error={Boolean(touched.description && errors.description)}
          fullWidth
          helperText={touched.description && errors.description}
          label="Description"
          margin="normal"
          name="description"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.description}
          variant="outlined"
        />
      </FormControl>
    </Grid>
  );
}
