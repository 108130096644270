export default `query getDistrict($id: ID!){
  getDistrict(id: $id){	
    id
    wards {
      id
      ghn_id
      description {
        name
      }
    }
  }
}`;
