export default `query getCategoryList($limit: Int, $offset: Int, $keyword: String $language_code: String $status: StatusType){
  getCategoryList(limit: $limit, offset: $offset, keyword: $keyword language_code: $language_code status: $status) {
    count
    data{
      id
      description {
        title
      }
    }
  }
}`;
