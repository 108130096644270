import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import GlobalStyles from "src/components/GlobalStyles";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { notificationSelector } from "src/state/notification/reducer";
import "src/mixins/chartjs";
import routes from "src/routes";
import { COOKIE_TOKEN_NAME } from "src/utils/constant";

const App = () => {
  const isAuth = Cookies.get(COOKIE_TOKEN_NAME);
  const routing = useRoutes(routes(isAuth));
  const { enqueueSnackbar } = useSnackbar();
  const { message } = useSelector(notificationSelector);
  useEffect(() => {
    if (message) {
      enqueueSnackbar(message.text, { variant: message.type });
    }
  }, [message]);
  return (
    <>
      <GlobalStyles />
      {routing}
    </>
  );
};

export default App;
