export default `query getProvince($id: ID!){
  getProvince(id: $id){	
    id
    districts {
      id
      ghn_id
      description {
        name
      }
    }
  }
}`;
