import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  FormControl,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";

import { roleList, languageList } from "./data";
import { useDispatch, useSelector } from "react-redux";
import {
  permissionListSelector,
  getList as getAllPermission,
} from "src/state/permission/reducer";
import { capitalizeFirstLetter } from "../../utils/logic";
import { status } from "nprogress";

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "left",
    padding: "1.25rem",
    boxShadow: "0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
    },
  },
}));

export default function PermissionRoleForm({
  dataRole,
  permissions,
  statusCheckbox,
  setStatusCheckbox,
}) {
  const classes = useStyles();
  // console.log(dataRole);
  // console.log(statusCheckbox);
  useEffect(() => {
    if (dataRole?.id) {
      const formatData = dataRole?.permission_role?.map((per) => {
        return {
          [per.description.name]: {
            id: per.permission_id,
            isChecked: true,
          },
        };
      });

      const formatObj = formatData?.reduce((acc, obj) => {
        return Object.assign(acc, obj);
      }, {});

      setStatusCheckbox((prev) => {
        return {
          ...prev,
          ...formatObj,
        };
      });
    }
  }, [dataRole]);

  const handleChangeCheckbox = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    const value = event.target.value;

    const defaultAll = ["list", "detail", "create", "edit", "del"];
    const defaultValue = "0";

    if (defaultAll.includes(name)) {
      let tempArray = [];
      for (const [key, value] of Object.entries(statusCheckbox)) {
        if (key.includes(`${name}-`)) {
          tempArray.push({
            [key]: {
              ...value,
              isChecked: checked,
            },
          });
        }
      }
      const formatObj = tempArray.reduce((acc, obj) => {
        return Object.assign(acc, obj);
      }, {});

      setStatusCheckbox((prev) => {
        return {
          ...prev,
          ...formatObj,
          [name]: checked,
        };
      });
      return;
    }

    setStatusCheckbox((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          isChecked: checked,
        },
      };
    });
  };

  return (
    <Grid item xs={12} md={8}>
      <FormControl classes={{ root: classes.form }}>
        <Typography
          variant="h6"
          color="default"
          classes={{ root: classes.typo }}
        >
          Permission Role
        </Typography>
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={statusCheckbox.list}
                      onChange={handleChangeCheckbox}
                      name="list"
                      color="primary"
                    />
                  }
                  label="Xem danh sách"
                />
              </TableCell>

              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={statusCheckbox.detail}
                      onChange={handleChangeCheckbox}
                      name="detail"
                      color="primary"
                    />
                  }
                  label="Xem chi tiết"
                />
              </TableCell>

              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={statusCheckbox.create}
                      onChange={handleChangeCheckbox}
                      name="create"
                      color="primary"
                    />
                  }
                  label="Thêm"
                />
              </TableCell>

              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={statusCheckbox.edit}
                      onChange={handleChangeCheckbox}
                      name="edit"
                      color="primary"
                    />
                  }
                  label="Sửa"
                />
              </TableCell>

              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={statusCheckbox.del}
                      onChange={handleChangeCheckbox}
                      name="del"
                      color="primary"
                    />
                  }
                  label="Xoá"
                />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {permissions?.map((permission) => (
              <TableRow>
                <TableCell>
                  {capitalizeFirstLetter(
                    permission?.name?.split("-").join(" ")
                  )}
                </TableCell>
                <TableCell>
                  {permission.list.length ? (
                    <Checkbox
                      checked={
                        statusCheckbox[permission?.list?.[0]?.name].isChecked
                      }
                      onChange={(e) => {
                        handleChangeCheckbox(e);
                      }}
                      name={permission?.list?.[0]?.name}
                      color="primary"
                      value={permission?.list?.[0]?.id}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>

                <TableCell>
                  {permission.detail.length ? (
                    <Checkbox
                      checked={
                        statusCheckbox[permission?.detail?.[0]?.name].isChecked
                      }
                      onChange={(e) => {
                        handleChangeCheckbox(e);
                      }}
                      name={permission?.detail?.[0]?.name}
                      color="primary"
                      value={permission?.detail?.[0]?.id}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>

                <TableCell>
                  {permission.create.length ? (
                    <Checkbox
                      checked={
                        statusCheckbox[permission?.create?.[0]?.name].isChecked
                      }
                      onChange={(e) => {
                        handleChangeCheckbox(e);
                      }}
                      name={permission?.create?.[0]?.name}
                      color="primary"
                      value={permission?.create?.[0]?.id}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>

                <TableCell>
                  {permission.edit.length ? (
                    <Checkbox
                      checked={
                        statusCheckbox[permission?.edit?.[0]?.name].isChecked
                      }
                      onChange={(e) => {
                        handleChangeCheckbox(e);
                      }}
                      name={permission?.edit?.[0]?.name}
                      color="primary"
                      value={permission?.edit?.[0]?.id}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>

                <TableCell>
                  {permission.del.length ? (
                    <Checkbox
                      checked={
                        statusCheckbox[permission?.del?.[0]?.name].isChecked
                      }
                      onChange={(e) => {
                        handleChangeCheckbox(e);
                      }}
                      name={permission?.del?.[0]?.name}
                      color="primary"
                      value={permission?.del?.[0]?.id}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </FormControl>
    </Grid>
  );
}
