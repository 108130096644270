export const paymentTypeList = [
  {
    "id": 4,
    "name": "Chuyển khoản tại Việt Nam",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 3,
    "name": "Chuyển khoản tại Úc (AUD)",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 2,
    "name": "Trả Cash tại nơi gửi hàng",
    "language_code": "vi",
    "deleted_at": null
  },
  {
    "id": 1,
    "name": "Trả tiền khi nhận hàng",
    "language_code": "vi",
    "deleted_at": null
  }
].map((item) => ({ Index: item.id, Title: item.name })).reverse();

export const languageList = [
  {
    "id": 4,
    "code": "vi",
    "name": "Tiếng Việt",
    "priority": 1,
    "flag_icon": "https://dashboard.epispost.com//cms/images/assets/no-img_50x50.png",
    "vi_name": null,
    "created_at": null,
    "updated_at": "2020-07-21T13:22:43.000000Z",
    "deleted_at": null
  }
].map((item) => ({
  id: item.id,
  value: item.code,
  label: item.name
}));

export const statusList = [
  {
    id: '1',
    value: 'active',
    label: 'Active'
  },
  {
    id: '2',
    value: 'de_active',
    label: 'DeActive'
  }
]
