import { createSlice } from '@reduxjs/toolkit';
export const namespace = 'notification';

const initialState = {
  message: null
};

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    pushNotification: (state, action) => ({
      ...state,
      message: action.payload
    })
  }
});

// Action creators are generated for each case reducer function
export const { pushNotification } = slice.actions;
// Selector
export const notificationSelector = (store) => store[namespace];
// Reducer
export const { reducer } = slice;

export { rootSagas as saga } from './saga';