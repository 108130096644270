import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { settingListSelector, getList } from "src/state/setting/reducer";
import {
  getList as getOrderStatusList,
  orderStatusListSelector,
} from "src/state/order-status-list/reducer";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid } from "@material-ui/core";
import PageTitle from "src/components/PageTitle";
import MyInput from "src/components/Input";
import MySelect from "src/components/Select";
import MyButton from "src/components/Button";
import MultiSelect from "src/components/MultiSelect";

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "left",
    padding: "1.25rem",
    boxShadow: "0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "42%",
    },
  },
  formFull: {
    textAlign: "left",
    padding: "1.25rem",
    boxShadow: "0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)",
    width: "100%",
    margin: "20px 0",
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
    },
  },
  typoHead: {
    background: "#efefef",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
    },
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: 5,
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "&:: -webkit - scrollbar - thumb: hover": {
      background: "#555",
    },
  },
  myHead: {
    background: "#eee",
  },
  myCell: {
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  myCellLast: {
    border: "none !important",
  },
  myCellAction: {
    border: "1px solid rgba(224, 224, 224, 1) !important",
    width: "50px !important",
    textAlign: "center",
  },
  logoContainer: {
    width: 180,
    height: 50,
    boxSizing: "border-box",
    border: "1px dashed #d9d9d9",
    cursor: "pointer",
  },
  logo: {
    objectFit: "cover",
    width: 180,
    height: 50,
    verticalAlign: "middle",
    aspectRatio: "auto 180 / 50",
  },
  iconContainer: {
    width: 40,
    height: 40,
    boxSizing: "border-box",
    border: "1px dashed #d9d9d9",
    cursor: "pointer",
  },
  icon: {
    objectFit: "cover",
    width: 40,
    height: 40,
    verticalAlign: "middle",
    aspectRatio: "auto 40 / 40",
  },
}));

import { languageList } from "../order/data";

export default function SettingDetail() {
  const classes = useStyles();
  const { loading, data, error } = useSelector(settingListSelector);
  const {
    loading: loadingOrderStatusList,
    data: orderStatusList,
  } = useSelector(orderStatusListSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getList());
    dispatch(
      getOrderStatusList({
        limit: 100,
        offset: 0,
        keyword: "",
        language_code: "vi",
        status: "active",
      })
    );
  }, []);

  const getSetting = (key) => {
    const setting = data.find((item) => item.key === key);
    return setting || {};
  };

  const getCronJob = () => {
    const cronJobIds = JSON.parse(
      getSetting("config_order_cron_job").value
    ).map((item) => JSON.stringify(item));
    const cronJobs = orderStatusList
      .map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .filter((item) => cronJobIds.includes(item.value));
    return cronJobs;
  };

  const getMemberNotDelete = () => {
    const cronJobIds = JSON.parse(
      getSetting("config_order_member_not_delete").value
    ).map((item) => JSON.stringify(item));
    const cronJobs = orderStatusList
      .map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .filter((item) => cronJobIds.includes(item.value));
    return cronJobs;
  };

  return (
    <>
      <PageTitle>Setting</PageTitle>
      <Box
        p={2}
        mt={2}
        style={{ background: "white", textAlign: "center" }}
        borderRadius={2}
      >
        {loading ? (
          <Typography variant="h4">Loading...</Typography>
        ) : error ? (
          <Typography variant="h4" color="secondary">
            {error}
          </Typography>
        ) : !data || data.length === 0 ? (
          <Typography variant="h4">No results</Typography>
        ) : (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box classes={{ root: classes.formFull }}>
                  <Typography
                    variant="h5"
                    color="default"
                    classes={{ root: classes.typoHead }}
                  >
                    General
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Typography variant="h6" color="default">
                        Url Cms
                      </Typography>
                      <MyInput
                        value={getSetting("config_url_cms").value}
                        disabled
                      />
                      <Typography variant="h6" color="default">
                        CurrencyViToAud
                      </Typography>
                      <MyInput
                        value={getSetting("config_currency_vi_to_aud").value}
                      />
                      <Typography variant="h6" color="default">
                        FeeShip Standard (Aud/kg)
                      </Typography>
                      <MyInput value={getSetting("config_fee_ship").value} />
                      <Typography variant="h6" color="default">
                        FeeShip Express (Aud/kg)
                      </Typography>
                      <MyInput
                        value={getSetting("config_fee_ship_express").value}
                      />
                      <Typography variant="h6" color="default">
                        Language Default
                      </Typography>
                      <MySelect
                        label="Chọn ngôn ngữ"
                        options={languageList}
                        variant="outlined"
                        defaultValue={languageList[0].value}
                      />
                      <Typography variant="h6" color="default">
                        Theme Version
                      </Typography>
                      <MyInput
                        value={getSetting("config_theme_version").value}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Typography variant="h6" color="default">
                        Storage Domain
                      </Typography>
                      <MyInput
                        value={getSetting("config_storage_domain").value}
                        disabled
                      />
                      <Typography variant="h6" color="default">
                        CurrencyAudToVi
                      </Typography>
                      <MyInput
                        value={getSetting("config_currency_aud_to_vi").value}
                      />
                      <Typography variant="h6" color="default">
                        CurrencyUnit
                      </Typography>
                      <MyInput value={getSetting("config_currency").value} />
                      <Typography variant="h6" color="default">
                        Language Default Admin
                      </Typography>
                      <MySelect
                        label="Chọn ngôn ngữ"
                        options={languageList}
                        variant="outlined"
                        defaultValue={languageList[0].value}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box classes={{ root: classes.formFull }}>
                  <Typography
                    variant="h5"
                    color="default"
                    classes={{ root: classes.typoHead }}
                  >
                    Đơn hàng
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Typography variant="h6" color="default">
                        Đơn hàng bắt đầu
                      </Typography>
                      {orderStatusList && (
                        <MySelect
                          label="Chọn ngôn ngữ"
                          options={orderStatusList?.map((item) => ({
                            ...item,
                            label: item.name,
                            value: item.id,
                          }))}
                          variant="outlined"
                          defaultValue={
                            getSetting("config_order_start_status_id").value
                          }
                        />
                      )}

                      <Typography variant="h6" color="default">
                        Đơn hàng hoàn thành
                      </Typography>
                      {orderStatusList && (
                        <MySelect
                          options={orderStatusList?.map((item) => ({
                            ...item,
                            label: item.name,
                            value: item.id,
                          }))}
                          variant="outlined"
                          defaultValue={
                            getSetting("config_order_delivered_status_id").value
                          }
                        />
                      )}
                      {console.log(
                        getSetting("config_order_delivered_status_id").value
                      )}
                      <Typography variant="h6" color="default">
                        Trạng thái đơn hàng chạy cron job
                      </Typography>
                      {orderStatusList && (
                        <MultiSelect
                          options={orderStatusList.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          value={getCronJob()}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Typography variant="h6" color="default">
                        Hàng về Việt Nam (giao cho GHN)
                      </Typography>
                      {orderStatusList && (
                        <MySelect
                          label="Chọn ngôn ngữ"
                          options={orderStatusList.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          variant="outlined"
                          defaultValue={
                            getSetting("config_order_vietnam_status_id").value
                          }
                        />
                      )}
                      <Typography variant="h6" color="default">
                        Đơn hàng hủy
                      </Typography>
                      {orderStatusList && (
                        <MySelect
                          label="Chọn ngôn ngữ"
                          options={orderStatusList.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          variant="outlined"
                          defaultValue={
                            getSetting("config_order_cancel_status_id").value
                          }
                        />
                      )}

                      <Typography variant="h6" color="default">
                        Trạng thái thành viên không thể hủy đơn
                      </Typography>
                      {orderStatusList && (
                        <MultiSelect
                          options={orderStatusList.map((item) => ({
                            value: item.id,
                            label: item.label,
                          }))}
                          value={getMemberNotDelete()}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box classes={{ root: classes.formFull }}>
              <Typography
                variant="h5"
                color="default"
                classes={{ root: classes.typoHead }}
              >
                Store
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="h6" color="default">
                    Storage Name
                  </Typography>
                  <MyInput value={getSetting("config_name").value} disabled />
                  <Typography variant="h6" color="default">
                    Địa chỉ
                  </Typography>
                  <MyInput value={getSetting("config_address").value} />
                  <Typography variant="h6" color="default">
                    Email
                  </Typography>
                  <MyInput value={getSetting("config_email").value} />
                  <Typography variant="h6" color="default">
                    Telephone
                  </Typography>
                  <MyInput value={getSetting("config_telephone").value} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography variant="h6" color="default">
                    Facebook
                  </Typography>
                  <MyInput
                    value={getSetting("config_facebook").value}
                    disabled
                  />
                  <Typography variant="h6" color="default">
                    Meta Title
                  </Typography>
                  <MyInput value={getSetting("config_meta_title").value} />
                  <Typography variant="h6" color="default">
                    Meta Description
                  </Typography>
                  <MyInput
                    value={getSetting("config_meta_description").value}
                    multiline
                    rows={3}
                    rowsMax={3}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box classes={{ root: classes.formFull }}>
                  <Typography
                    variant="h5"
                    color="default"
                    classes={{ root: classes.typoHead }}
                  >
                    Image
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Typography variant="h6" color="default">
                        Store Logo
                      </Typography>
                      <div
                        className={classes.logoContainer}
                        onClick={() => document.getElementById("logo").click()}
                      >
                        <img
                          src={`https://dashboard.epispost.com/${
                            getSetting("config_logo").value
                          }`}
                          className={classes.logo}
                          id="img_logo"
                        />
                      </div>
                      <input
                        type="file"
                        id="logo"
                        name="img"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          var output = document.getElementById("img_logo");
                          output.src = URL.createObjectURL(
                            event.target.files[0]
                          );
                          output.onload = function() {
                            URL.revokeObjectURL(output.src); // free memory
                          };
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Typography variant="h6" color="default">
                        Icon
                      </Typography>
                      <div
                        className={classes.iconContainer}
                        onClick={() => document.getElementById("icon").click()}
                      >
                        <img
                          src={`https://dashboard.epispost.com/${
                            getSetting("config_icon").value
                          }`}
                          className={classes.icon}
                          id="img_icon"
                        />
                      </div>
                      <input
                        type="file"
                        id="icon"
                        name="img"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          var output = document.getElementById("img_icon");
                          output.src = URL.createObjectURL(
                            event.target.files[0]
                          );
                          output.onload = function() {
                            URL.revokeObjectURL(output.src); // free memory
                          };
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box classes={{ root: classes.formFull }}>
                  <Typography
                    variant="h5"
                    color="default"
                    classes={{ root: classes.typoHead }}
                  >
                    Server
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Typography variant="h6" color="default">
                        Maintenance
                      </Typography>
                      <MySelect
                        // label="Chọn ngôn ngữ"
                        options={[
                          {
                            value: "0",
                            label: "No",
                          },
                          {
                            value: "1",
                            label: "Yes",
                          },
                        ]}
                        variant="outlined"
                        defaultValue={getSetting("config_maintenance").value}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Typography variant="h6" color="default">
                        Debug
                      </Typography>
                      <MySelect
                        // label="Chọn ngôn ngữ"
                        options={[
                          {
                            value: "0",
                            label: "No",
                          },
                          {
                            value: "1",
                            label: "Yes",
                          },
                        ]}
                        variant="outlined"
                        defaultValue={getSetting("config_debug").value}
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="h6" color="default">
                    Email Receive Notification
                  </Typography>
                  <MyInput
                    value={getSetting("config_email_notification").value}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}
