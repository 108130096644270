export const categoryList = [
  {
    "id": 18,
    "title": "Nước hoa (<= 100ml)",
    "deleted_at": null
  },
  {
    "id": 17,
    "title": "Nước hoa (<= 50ml)",
    "deleted_at": null
  },
  {
    "id": 16,
    "title": "Phụ kiện có phí",
    "deleted_at": null
  },
  {
    "id": 14,
    "title": "Trang sức",
    "deleted_at": null
  },
  {
    "id": 13,
    "title": "Màn hình máy tính",
    "deleted_at": null
  },
  {
    "id": 11,
    "title": "Phụ tùng xây dựng",
    "deleted_at": null
  },
  {
    "id": 10,
    "title": "Link kiện điện tử",
    "deleted_at": null
  },
  {
    "id": 9,
    "title": "Linh kiện máy tính",
    "deleted_at": null
  },
  {
    "id": 8,
    "title": "Phụ tùng oto",
    "deleted_at": null
  },
  {
    "id": 7,
    "title": "Hàng điện tử",
    "deleted_at": null
  },
  {
    "id": 6,
    "title": "Đồ gia dụng",
    "deleted_at": null
  },
  {
    "id": 5,
    "title": "Hàng thông thường",
    "deleted_at": null
  },
  {
    "id": 4,
    "title": "Quần áo, giày dép",
    "deleted_at": null
  },
  {
    "id": 3,
    "title": "Sữa bột, sữa nước",
    "deleted_at": null
  },
  {
    "id": 2,
    "title": "Mỹ phẩm",
    "deleted_at": null
  },
  {
    "id": 1,
    "title": "Thực phẩm chức năng",
    "deleted_at": null
  }
].map((item) => ({ Index: item.id, Title: item.title })).reverse();

export const languageList = [
  {
    "id": 4,
    "code": "vi",
    "name": "Tiếng Việt",
    "priority": 1,
    "flag_icon": "https://dashboard.epispost.com//cms/images/assets/no-img_50x50.png",
    "vi_name": null,
    "created_at": null,
    "updated_at": "2020-07-21T13:22:43.000000Z",
    "deleted_at": null
  }
].map((item) => ({
  id: item.id,
  value: item.code,
  label: item.name
}));

export const statusList = [
  {
    id: '1',
    value: 'active',
    label: 'Active'
  },
  {
    id: '2',
    value: 'de_active',
    label: 'DeActive'
  }
]
