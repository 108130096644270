import { createSlice } from '@reduxjs/toolkit';
export const namespace = 'province';

const initialState = {
  loading: true,
  error: null,
  data: null
};

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    get: (state) => ({
      ...state,
      // loading: true,
    }),
    getSuccess: (state, action) => ({
      ...state,
      error: null,
      data: action.payload,
      loading: false,
    }),
    getError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: null,
    }),
  }
});

// Action creators are generated for each case reducer function
export const { get, getSuccess, getError } = slice.actions;
// Selector
export const provinceSelector = (state) => state[namespace];
// Reducer
export const { reducer } = slice;

export { rootSagas as saga } from './saga';
