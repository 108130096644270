import _ from "lodash";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { get, getSuccess, getError } from "./reducer";
import callGraphqlApi from "src/utils/callGraphqlApi";
import { getRoleRedux } from "./query";

function* watchGetList({ payload: { payload, callback } }) {
  const resGetList = yield call(
    callGraphqlApi,
    "getList",
    getRoleRedux,
    payload
  );
  const errors = _.get(resGetList, "errors", null);

  if (callback) {
    if (errors) {
      yield put(getError(_.get(errors, "[0].message", null)));
      return callback(getError(_.get(errors, "[0].message", null)));
    }
    const data = _.get(resGetList, "data", null);

    if (data) {
      yield put(getSuccess(_.get(data, "getRole", [])));
      return callback(getSuccess(_.get(data, "getRole", [])));
    }
  }
}

export const rootSagas = function* rootSagas() {
  yield all([takeEvery(get.type, watchGetList)]);
};
