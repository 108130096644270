export const orderStatusList = [
  {
    "id": 11490,
    "ghn_id": "80409",
    "name": "Xã Phú Nhuận",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11489,
    "ghn_id": "80407",
    "name": "Xã Phố Lu",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11488,
    "ghn_id": "80410",
    "name": "Xã Sơn Hà",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11487,
    "ghn_id": "80413",
    "name": "Xã Trì Quang",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11486,
    "ghn_id": "80414",
    "name": "Xã Xuân Giao",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11485,
    "ghn_id": "80411",
    "name": "Xã Sơn Hải",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11484,
    "ghn_id": "80415",
    "name": "Xã Xuân Quang",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11483,
    "ghn_id": "80406",
    "name": "Xã Gia Phú",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11482,
    "ghn_id": "80408",
    "name": "Xã Phong Niên",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11481,
    "ghn_id": "80412",
    "name": "Xã Thái Niên",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11480,
    "ghn_id": "80404",
    "name": "Xã Bản Cầm",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11479,
    "ghn_id": "80405",
    "name": "Xã Bản Phiệt",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11478,
    "ghn_id": "80403",
    "name": "Thị trấn Tằng Loỏng",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11477,
    "ghn_id": "80402",
    "name": "Thị trấn Phố Lu",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11476,
    "ghn_id": "80401",
    "name": "Thị trấn N.T Phong Hải",
    "district_id": 716,
    "deleted_at": null,
    "district": {
      "id": 716,
      "ghn_id": 2073,
      "province_id": 63,
      "code": "0804",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": "2020-11-18T01:30:21.000000Z",
      "deleted_at": null,
      "district_description": {
        "district_id": 716,
        "language_code": "vi",
        "name": "Huyện Bảo Thắng",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-18T02:30:21.000000Z"
      }
    }
  },
  {
    "id": 11475,
    "ghn_id": "80616",
    "name": "Xã Nậm Xây",
    "district_id": 715,
    "deleted_at": null,
    "district": {
      "id": 715,
      "ghn_id": 2043,
      "province_id": 63,
      "code": "0806",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": null,
      "deleted_at": null,
      "district_description": {
        "district_id": 715,
        "language_code": "vi",
        "name": "Huyện Văn Bàn",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-17T04:44:36.000000Z"
      }
    }
  },
  {
    "id": 11474,
    "ghn_id": "80610",
    "name": "Xã Liêm Phú",
    "district_id": 715,
    "deleted_at": null,
    "district": {
      "id": 715,
      "ghn_id": 2043,
      "province_id": 63,
      "code": "0806",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": null,
      "deleted_at": null,
      "district_description": {
        "district_id": 715,
        "language_code": "vi",
        "name": "Huyện Văn Bàn",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-17T04:44:36.000000Z"
      }
    }
  },
  {
    "id": 11473,
    "ghn_id": "80621",
    "name": "Xã Thẩm Dương",
    "district_id": 715,
    "deleted_at": null,
    "district": {
      "id": 715,
      "ghn_id": 2043,
      "province_id": 63,
      "code": "0806",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": null,
      "deleted_at": null,
      "district_description": {
        "district_id": 715,
        "language_code": "vi",
        "name": "Huyện Văn Bàn",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-17T04:44:36.000000Z"
      }
    }
  },
  {
    "id": 11472,
    "ghn_id": "80611",
    "name": "Xã Minh Lương",
    "district_id": 715,
    "deleted_at": null,
    "district": {
      "id": 715,
      "ghn_id": 2043,
      "province_id": 63,
      "code": "0806",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": null,
      "deleted_at": null,
      "district_description": {
        "district_id": 715,
        "language_code": "vi",
        "name": "Huyện Văn Bàn",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-17T04:44:36.000000Z"
      }
    }
  },
  {
    "id": 11471,
    "ghn_id": "80615",
    "name": "Xã Nậm Tha",
    "district_id": 715,
    "deleted_at": null,
    "district": {
      "id": 715,
      "ghn_id": 2043,
      "province_id": 63,
      "code": "0806",
      "type": 3,
      "support_type": 3,
      "created_at": "2020-11-17 03:44:36",
      "updated_at": null,
      "deleted_at": null,
      "district_description": {
        "district_id": 715,
        "language_code": "vi",
        "name": "Huyện Văn Bàn",
        "created_at": "2020-11-17 04:44:36",
        "updated_at": "2020-11-17T04:44:36.000000Z"
      }
    }
  }
].map((item) => ({ Index: item.id, Title: item.name })).reverse();

export const languageList = [
  {
    "id": 4,
    "code": "vi",
    "name": "Tiếng Việt",
    "priority": 1,
    "flag_icon": "https://dashboard.epispost.com//cms/images/assets/no-img_50x50.png",
    "vi_name": null,
    "created_at": null,
    "updated_at": "2020-07-21T13:22:43.000000Z",
    "deleted_at": null
  }
].map((item) => ({
  id: item.id,
  value: item.code,
  label: item.name
}));

export const statusList = [
  {
    id: '1',
    value: 'active',
    label: 'Active'
  },
  {
    id: '2',
    value: 'de_active',
    label: 'DeActive'
  }
]

export const carrierList = [
  {
    "id": 1,
    "name": "Giao hàng nhanh",
    "deleted_at": null
  }
]
