import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core";
import store from "src/state/index";
import theme from "src/theme";

import { Provider } from 'react-redux'
import App from "./App";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          hideIconVariant={true}
        >
          <App />
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
