import { baseUrlApi } from "./config";
import Cookies from "js-cookie";
import { COOKIE_TOKEN_NAME } from "../utils/constant";
export default function callGraphqlApi(
  operatorName,
  query,
  variables,
  needAuth = true
) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (needAuth) headers["Authorization"] = Cookies.get(COOKIE_TOKEN_NAME);

  return fetch(baseUrlApi, {
    method: "POST",
    headers,
    body: JSON.stringify({
      operatorName,
      query,
      variables,
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      if (
        data.errors &&
        [
          "You must be logged in",
        ].includes(data.errors[0].message)
      ) {
        Cookies.remove(COOKIE_TOKEN_NAME);
        window.location.pathname = "/login";
      }
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return { error: "Call Graphql Error!" };
    });
}
