import { createSlice } from "@reduxjs/toolkit";

export const namespace = "user-one";

const initialState = {
  loading: true,
  error: null,
  data: null,
};

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    get: (state) => ({
      ...state,
    }),
    getSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      data: action.payload,
    }),
    getError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: null,
    }),
    resetState: (state) => ({
      ...initialState,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { get, getSuccess, getError, resetState } = slice.actions;
// Selector
export const userOneSelector = (state) => state[namespace];
// Reducer
export const { reducer } = slice;

export { rootSagas as saga } from "./saga";
