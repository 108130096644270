import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  navLink: {
    color: theme.palette.text.primary,
  },
  navLinkActive: {
    color: theme.palette.text.active,
  },
  textBody1: {
    fontSize: theme.typography.body1,
  },
}));

export default function MenuItem({ title, icon, children, href = "/" }) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if (!children) {
      navigate(href, { replace: true });
      return;
    }
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText classes={{ root: classes.textBody1 }}>
          {title}
        </ListItemText>
        {children ? open ? <ExpandLess /> : <ExpandMore /> : <></>}
      </ListItem>

      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children?.map((item) => (
              <NavLink
                key={item.title}
                to={item.href}
                className={
                  item.href === location.pathname
                    ? classes.navLinkActive
                    : classes.navLink
                }
              >
                <ListItem button className={classes.nested}>
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  <ListItemText classes={{ root: classes.textBody1 }}>
                    {item.title}
                  </ListItemText>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
