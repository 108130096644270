export default `query getList($limit: Int $offset: Int $keyword: String) {
  getWardList(limit: $limit offset: $offset keyword: $keyword){
    count
    data {
      id
      ghn_id
      description {
        name
      }
      district {
        description {
          name
        }
      }
    }
  }
}`;
