export const getPermissionList = `query getPermissionList($limit: Int, $offset: Int){
  getPermissionList(limit: $limit, offset: $offset) {
    count
    data {
      id
      name
      display_name
      description
      created_at
    }
  }
}`;
