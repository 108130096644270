import { createSlice } from "@reduxjs/toolkit";

export const namespace = "permissionList";

const initialState = {
  loading: true,
  error: null,
  data: null,
  count: 0,
};

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getList: (state) => ({
      ...state,
    }),
    getListSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      data: action.payload.data,
      count: action.payload.count,
    }),
    getListError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: null,
      count: 0,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { getList, getListSuccess, getListError } = slice.actions;
// Selector
export const permissionListSelector = (state) => state[namespace];
// Reducer
export const { reducer } = slice;

export { rootSagas as saga } from "./saga";
