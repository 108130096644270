import React, { useLayoutEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { PDFViewer } from "@react-pdf/renderer";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import MyDocument from "./MyDocument";
import MyButton from "src/components/Button";
import {
  orderSelector,
  getOrderReducer,
  resetOrder,
} from "src/state/order-one/reducer";
import { Typography } from "@material-ui/core";

export default function OrderBilling() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, data: dataOrder } = useSelector(orderSelector);

  useLayoutEffect(() => {
    dispatch(
      getOrderReducer({
        payload: id,
      })
    );

    return () => dispatch(resetOrder());
  }, []);

  return (
    <>
      <MyButton
        variant="contained"
        color="default"
        onClick={() => {
          navigate("/vcms/order/list", { replace: true });
        }}
        style={{ marginBottom: 10, color: "#fff" }}
      >
        <KeyboardBackspaceIcon />
        Quay lại danh sách
      </MyButton>

      {loading ? (
        <Typography variant="h3" color="default">
          Loading...
        </Typography>
      ) : (
        <div style={{ width: "100%", height: "calc(100vh - 185px)" }}>
          <PDFViewer width="100%" style={{ height: "inherit" }}>
            <MyDocument dataOrder={dataOrder} />
          </PDFViewer>
        </div>
      )}
    </>
  );
}
