import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { appSelector } from "src/state/app/reducer";
import {
  orderListSelector,
  getList as getOrderList,
  deleteOrder,
} from "src/state/order-list/reducer";
import PageTitle from "src/components/PageTitle";
import MyTable from "src/components/Table";
import MyInput from "src/components/Input";
import MySelect from "src/components/Select";
import MyButton from "src/components/Button";

import { languageList } from "./data";
import { statusList } from "src/utils/constant";

import { pushNotification } from "src/state/notification/reducer";
const limit = 20;

export default function OrderList() {
  const dispatch = useDispatch();
  const { device } = useSelector(appSelector);
  const { loading, error, data, count } = useSelector(orderListSelector);

  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(statusList[0].value);
  const [language, setLanguage] = useState(languageList[0]);

  useEffect(() => {
    handleSearch();
  }, []);

  const handleDelete = (item) => {
    dispatch(
      deleteOrder({
        payload: {
          id: parseInt(item.Index),
        },
        callback: (res) => {
          if (res.error) {
            console.error(error);
            dispatch(
              pushNotification({
                text: "Xoa loi",
                type: "error",
              })
            );
          }
          console.log(data);
          if (res.data) {
            dispatch(
              pushNotification({
                text: "Xoa thanh cong",
                type: "success",
              })
            );
            getOrderListFunc(
              (page - 1) * limit,
              keyword,
              language.value,
              status
            );
          }
        },
      })
    );
  };

  const getOrderListFunc = (offset, keyword, language_code, status) => {
    dispatch(
      getOrderList({
        limit,
        offset,
        keyword,
        language_code,
        status,
      })
    );
  };

  const handleSearch = () => {
    setPage(1);
    getOrderListFunc(0, keyword, language.value, status);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getOrderListFunc((newPage - 1) * limit, keyword, language.value, status);
  };

  const handleChangeLanguage = (e) => {
    const selected = languageList.find((item) => item.value === e.target.value);
    setLanguage(selected);
  };

  const handleChangeStatus = (e) => {
    const status = e.target.value;
    // getOrderListFunc((page - 1) * limit, keyword, language.value, status);
    setStatus(status);
  };

  const handleSearchOrder = () => {
    getOrderListFunc((page - 1) * limit, keyword, language.value, status);
  };

  return (
    <>
      <PageTitle>Order List</PageTitle>
      <Box p={2} mt={2} style={{ background: "white" }} borderRadius={2}>
        <Box mb={2}>
          <MyInput
            placeholder="Keyword"
            variant="outlined"
            width={device === 3 ? "unset" : null}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <MySelect
            label="Chọn ngôn ngữ"
            options={languageList}
            variant="outlined"
            width={device === 3 ? "unset" : null}
            value={language.value}
            onChange={handleChangeLanguage}
          />
          <MySelect
            label="Select status"
            options={statusList}
            variant="outlined"
            width={device === 3 ? "unset" : null}
            value={status}
            onChange={handleChangeStatus}
          />
          <MyButton text="Search" color="primary" onClick={handleSearchOrder} />
          <NavLink to="/vcms/order/add">
            <MyButton text="Add" color="success" />
          </NavLink>
        </Box>

        {loading ? (
          <Typography variant="h4">Loading...</Typography>
        ) : error ? (
          <Typography variant="h4" color="secondary">
            {error}
          </Typography>
        ) : !data || data.length === 0 ? (
          <Typography variant="h4">No results</Typography>
        ) : (
          <MyTable
            data={data.map((item) => ({
              Index: item.id,
              ["Mã tờ khai"]: item.code,
              ["Họ tên người gửi"]: item.order_send?.name,
              ["Số điện thoại người gửi"]: item.order_send?.phone,
              ["Địa chỉ người gửi"]: item.order_send?.address,
              ["Họ tên người nhận"]: item.order_recipient?.name,
              ["Số điện thoại người nhận"]: item.order_recipient?.phone,
              ["Địa chỉ người nhận"]: item.order_recipient?.address,
              ["Phí vận chuyển (tạm tính)"]: `${item.fee} (${item.currency})`,
            }))}
            page={page}
            onChange={handleChangePage}
            onDelete={handleDelete}
            count={
              count % limit === 0 ? count / limit : parseInt(count / limit) + 1
            }
            category="order"
            isPrintPDF
            status={data && data[0]?.deleted_at ? "deactive" : "active"}
          />
        )}
      </Box>
    </>
  );
}
