import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import Page from "src/components/Page";
import { resetPassword } from "src/state/auth/reducer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorResetPassword, setErrorResetPassword] = useState("");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const handleResetPassword = (values, callback) => {
    dispatch(
      resetPassword({
        payload: {
          email: query.get("email"),
          password: values?.password,
          forgotPasswordToken: query.get("token"),
        },
        callback: (res) => {
          if (res?.errors?.length) {
            callback.setSubmitting(false);
            setErrorResetPassword(res?.errors[0].message);
          }
          if (res?.data?.resetPassword?.status === "success") {
            navigate("/login", { replace: true });
          }
        },
      })
    );
  };

  return (
    <Page className={classes.root} title="Reset Password">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .max(255)
                .required("Password is required"),
            })}
            onSubmit={handleResetPassword}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={1}>
                  <Typography color="textPrimary" variant="h2">
                    Reset Password
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="New Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  variant="outlined"
                  type="password"
                />

                <Typography style={{ color: "red" }}>
                  {errorResetPassword}
                </Typography>

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ResetPassword;
