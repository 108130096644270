import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 20
    },
  },
  primary: {
    color: 'white',
    background: theme.palette.primary.main,
    '&:hover': {
      color: 'white',
      background: theme.palette.primary.main,
    }
  },
  default: {
    color: 'black',
    background: theme.palette.default.main,
    '&:hover': {
      color: 'black',
      background: theme.palette.default.main,
    }
  },
  secondary: {
    color: 'white',
    background: theme.palette.secondary.main,
    '&:hover': {
      color: 'white',
      background: theme.palette.secondary.main,
    }
  },
  success: {
    color: 'white',
    background: theme.palette.success.main,
    '&:hover': {
      color: 'white',
      background: theme.palette.success.main,
    }
  },
}));


export default function MyButton(props) {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={`
      ${classes[props.color]}
      ${classes.root}
    `}>
      {props.text || props.children}
    </Button>
  );
}
