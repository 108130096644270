import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `0.5px solid ${theme.palette.default.main}`,
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: 8,
    '& input': {
      padding: 0
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 20
    },
  },
  focused: {
    border: `0.5px solid ${theme.palette.primary.main}`,
  }
}));

export default function MyInput(props) {
  const classes = useStyles();

  return (
    <InputBase
      color="primary"
      {...props}
      classes={{ root: classes.root, focused: classes.focused }}
      style={{ width: props.width ? 'unset' : '100%' }}
    />
  );
}
