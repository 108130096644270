import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > div': {
      height: 35.09,
      boxSizing: 'border-box',
      '& fieldset': {
        border: `.5px solid ${theme.palette.default.main} !important`
      }
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 20
    },
  },
  select: {
    '& select': {
      padding: 10,
    },
    '& option': {
      padding: 10,
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    top: '50%',
    width: '100%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  }
}));


export default function MySelect(props) {
  const classes = useStyles();
  return (
    <FormControl
      {...props} variant="outlined" classes={{ root: classes.form }}
      style={{ width: props.width ? 'unset' : '100%' }}
    >
      <Select
        className={classes.select}
        style={props.style}
        isMulti
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={(e) => props.onChange({ target: { value: e.value } })}
        options={props.options}
      />
    </FormControl>
  );
}
