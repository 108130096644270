import _ from 'lodash';
import { takeEvery, put, all, call } from 'redux-saga/effects';
import { getList, getListSuccess, getListError } from './reducer';
import callGraphqlApi from 'src/utils/callGraphqlApi';
import queryList from './query';

function* watchGetList({ payload }) {
  const resGetList = yield call(callGraphqlApi, "getList", queryList, payload);
  const errors = _.get(resGetList, 'errors', null);
  if (errors) {
    return yield put(getListError(_.get(errors, '[0].message', null)));
  }
  const data = _.get(resGetList, 'data', null);
  if (data) {
    yield put(getListSuccess(_.get(data, 'getCategoryList', [])))
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(getList.type, watchGetList)
  ]);
}
