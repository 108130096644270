export const getOrder = `query getOrder($id: ID!){
  getOrder(id: $id){	
    id
    config_fee_ship
    user {
      id
      username
      email
      confirmed
      confirm_code
      full_name
      country
      address
      image
      type
      created_at
      updated_at
      deleted_at
    }
    order_products {
      id
      amount
      price
      weight
      product_name
      category {
        id
        description {
          title
        }
      }
    }
    code
    order_status {
      id
      language_code
      name
      updated_at
      created_at
    }
    order_status_logs{
      id
      log
      order_status{
        id
        name
      }
      user{
        email
      }
      created_at
    }
    carrier{
      id
      name
      updated_at
      created_at
      deleted_at
      carrier_order_status {
        id
        carrier_id
        name
        updated_at
        created_at
        description {
          carrier_order_status_id
          language_code
          description
          updated_at
          created_at
        }
      }
    }
    code_carrier
    location
    payment_type {
      id
      name
      language_code
      updated_at
      created_at
      deleted_at
    }
    service_type_id
    price
    bales
    weight
    height
    length
    width
    number_customer
    note
    pickup
    pickup_date
    pickup_content
    surcharge
    fee
    currency
    warehouse_sydney
    warehouse_sydney_tracking
    warehouse_sydney_order
    updated_at
    created_at
    deleted_at
    order_recipient {
      name
      phone
      email
      country
      address
      created_at
      province {
        id
        description {
          name
        }
      }
      district {
        id
        description {
          name
        }
        ghn_id
      }
      ward {
        id
        description {
          name
        }
        ghn_id
      }
    }
    order_send {
      name
      phone
      email
      country
      address
      created_at
    }
  }
}`;

export const getFeeQuery = `query getFee($service_type_id: Int! $currency: CurrencyType! $to_district_id: Int! $to_ward_code: String! $height: Int! $length: Int! $width: Int! $weight: Int! $insurance_value: Int! $coupon: String!) {
  getFee(
    service_type_id: $service_type_id,
    currency: $currency,
    to_district_id: $to_district_id,
    to_ward_code: $to_ward_code,
    height: $height,
    length: $length,
    width: $width,
    weight: $weight,
    insurance_value: $insurance_value,
    coupon: $coupon
  ) {
    fee
  }
}`;

export const createOrderQuery = `
mutation createOrder (
  $order_status_id: Int!,
  $code_carrier: String,
  $location: String, 
  $order_send: OrderSendInput!, 
  $order_recipient: OrderRecipientInput!,
  $order_products: [OrderProductInput!]!,
  $code: String!,
  $price: Float!,
  $payment_type_id: Int!,
  $width: Float!,
  $height: Float!,
  $length: Float!,
  $bales: Int!,
  $weight: Float!,
  $currency: CurrencyType!,
  $fee: Float!,
  $surcharge: Float!,
  $service_type_id: Int,
  $note: String,
  $pickup: Int,
  $pickup_date: DateTime,
  $pickup_content: String,
  $warehouse_sydney: Int,
  $warehouse_sydney_order: String,
  $warehouse_sydney_tracking: String
  $to_district_id: Int!
  $to_ward_code: String!
  ) {
  createOrder(
    order_status_id: $order_status_id,
    code_carrier: $code_carrier,
    location: $location,
    order_send: $order_send,
    order_recipient: $order_recipient,
    order_products: $order_products,
    code: $code,
    price: $price,
    payment_type_id: $payment_type_id,
    width: $width,
    height: $height,
    length: $length,
    bales: $bales,
    weight: $weight,
    currency: $currency,
    fee: $fee,
    surcharge: $surcharge,
    service_type_id: $service_type_id,
    note: $note,
    pickup: $pickup,
    pickup_date: $pickup_date,
    pickup_content: $pickup_content,
    warehouse_sydney: $warehouse_sydney,
    warehouse_sydney_order: $warehouse_sydney_order,
    warehouse_sydney_tracking: $warehouse_sydney_tracking
    to_district_id: $to_district_id
    to_ward_code: $to_ward_code
  ) {
    id
  }
}
`;

export const updateOrderQuery = `
mutation updateOrder (
  $id: Int!
  $order_status_id: Int!,
  $code_carrier: String,
  $location: String, 
  $order_send: OrderSendInput!, 
  $order_recipient: OrderRecipientInput!,
  $order_products: [OrderProductInput!]!,
  $code: String!,
  $price: Float!,
  $payment_type_id: Int!,
  $width: Float!,
  $height: Float!,
  $length: Float!,
  $bales: Int!,
  $weight: Float!,
  $currency: CurrencyType!,
  $fee: Float!,
  $surcharge: Float!,
  $service_type_id: Int,
  $note: String,
  $pickup: Int,
  $pickup_date: DateTime,
  $pickup_content: String,
  $warehouse_sydney: Int,
  $warehouse_sydney_order: String,
  $warehouse_sydney_tracking: String
  $to_district_id: Int!
  $to_ward_code: String!
  ) {
  updateOrder(
    id: $id
    order_status_id: $order_status_id,
    code_carrier: $code_carrier,
    location: $location,
    order_send: $order_send,
    order_recipient: $order_recipient,
    order_products: $order_products,
    code: $code,
    price: $price,
    payment_type_id: $payment_type_id,
    width: $width,
    height: $height,
    length: $length,
    bales: $bales,
    weight: $weight,
    currency: $currency,
    fee: $fee,
    surcharge: $surcharge,
    service_type_id: $service_type_id,
    note: $note,
    pickup: $pickup,
    pickup_date: $pickup_date,
    pickup_content: $pickup_content,
    warehouse_sydney: $warehouse_sydney,
    warehouse_sydney_order: $warehouse_sydney_order,
    warehouse_sydney_tracking: $warehouse_sydney_tracking
    to_district_id: $to_district_id
    to_ward_code: $to_ward_code
  ) {
    id
  }
}
`;
