export const getUniqueId = () => new Date().getTime().toString(36);

export const formatInputGetFee = ({
  service_type_id,
  currency,
  to_district_id,
  to_ward_code,
  height,
  length,
  width,
  weight,
  insurance_value = 0,
  coupon = ""
}) => ({
service_type_id: parseInt(service_type_id),
  currency: currency.toString(),
  to_district_id: parseInt(to_district_id),
  to_ward_code: to_ward_code.toString(),
  height: parseInt(height), //cm
  length: parseInt(length),//cm
  width: parseInt(width),//cm
  weight: parseFloat(weight) * 1000,//g
  insurance_value: parseFloat(insurance_value),
  coupon: coupon.toString()
});
