import _ from 'lodash';
import { takeEvery, put, all, call } from 'redux-saga/effects';
import { get, getSuccess, getError } from './reducer';
import callGraphqlApi from 'src/utils/callGraphqlApi';
import query from './query';

function* watchGet({ payload }) {
  const resGet = yield call(callGraphqlApi, "get", query, payload);
  const errors = _.get(resGet, 'errors', null);
  if (errors) {
    return yield put(getError(_.get(errors, '[0].message', null)));
  }
  const data = _.get(resGet, 'data', null);
  if (data) {
    yield put(getSuccess(_.get(data, 'getProvince', [])))
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(get.type, watchGet),
  ]);
}
