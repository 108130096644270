export const getRoleRedux = `
  query getRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      display_name
      description
      permission_role {
        permission_id
        role_id
        description {
          name
          display_name
          description
        }
      }
    }
  }
`;
