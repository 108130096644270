import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  FormControl,
  Grid,
  TextField,
  FormControlLabel,
  Snackbar,
  Checkbox,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";

import PageTitle from "src/components/PageTitle";
import MyInput from "src/components/Input";
import MySelect from "src/components/Select";
import MyButton from "src/components/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";

import { roleList, languageList } from "./data";
import { useDispatch, useSelector } from "react-redux";
import {
  permissionListSelector,
  getList as getAllPermission,
} from "src/state/permission/reducer";
import RoleForm from "./RoleForm";
import PermissionRoleForm from "./PerrmissionRoleForm";
import { Alert } from "@material-ui/lab";
import { createRole } from "src/state/role-list/reducer";
import {
  get as getRole,
  resetState,
  roleOneSelector,
} from "src/state/role-one/reducer";

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "left",
    padding: "1.25rem",
    boxShadow: "0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
    },
  },
}));

export default function RoleEdit() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: permissions, count } = useSelector(permissionListSelector);
  const { data: dataRole } = useSelector(roleOneSelector);

  const [messageCreateRole, setMessageEditRole] = useState({
    message: "",
    status: "error",
  });
  const [open, setOpen] = useState(false);

  const [statusCheckbox, setStatusCheckbox] = useState(null);

  const [formState, setFormState] = useState({
    name: "",
    display_name: "",
    description: "",
  });

  useEffect(() => {
    dispatch(
      getRole({
        payload: {
          id,
        },
        callback: (res) => {
          if (res?.payload?.id) {
            setFormState({
              name: res?.payload?.name,
              display_name: res?.payload?.display_name,
              description: res?.payload?.description,
            });
            return;
          }
          setMessageEditRole({
            message: res?.payload,
            status: "error",
          });
          setOpen(true);
        },
      })
    );

    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (permissions) {
      setStateStatus();
    }
  }, [permissions]);

  const setStateStatus = () => {
    const initState = permissions?.map((per) => {
      return {
        [per.name]: {
          id: per.id,
          isChecked: false,
        },
      };
    });

    const formatObjState = initState?.reduce((acc, obj) => {
      return Object.assign(acc, obj);
    }, {});

    setStatusCheckbox({
      list: false,
      detail: false,
      create: false,
      edit: false,
      del: false,
      ...formatObjState,
    });
    return;
  };

  useEffect(() => {
    dispatch(
      getAllPermission({
        limit: 10000,
      })
    );
  }, []);

  const permissionsWithName = permissions?.map((per) => {
    const splitName = per.name.split("-");
    const type = splitName.shift();
    const category = splitName.join("-");
    return {
      ...per,
      type,
      category,
    };
  });

  const formatData = permissionsWithName?.reduce((accumulator, obj) => {
    const category = obj["category"];
    if (!accumulator[category]) {
      accumulator[category] = [];
    }
    accumulator[category].push(obj);
    return accumulator;
  }, []);

  const convertToArray = formatData && Object.entries(formatData);

  const checkTypeAndAddStatus = (arr, type) => {
    return arr
      .filter((v) => v.type === type)
      .map((v) => ({ ...v, isChecked: false }));
  };

  const result =
    convertToArray?.length &&
    convertToArray?.map((item) => {
      const list = checkTypeAndAddStatus(item[1], "list");
      const detail = checkTypeAndAddStatus(item[1], "detail");
      const create = checkTypeAndAddStatus(item[1], "create");
      const edit = checkTypeAndAddStatus(item[1], "edit");
      const del = checkTypeAndAddStatus(item[1], "del");

      return {
        name: item[0],
        list,
        detail,
        create,
        edit,
        del,
      };
    });

  const handleEditRole = (values, callback) => {
    const { name = "", display_name = "", description = "", ...rest } = values;
    setMessageEditRole({
      message: "No found api editRole",
      status: "error",
    });
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <PageTitle>Edit Role</PageTitle>
      <Box
        p={2}
        mt={2}
        style={{ background: "white", textAlign: "center" }}
        borderRadius={2}
      >
        <Formik
          initialValues={formState}
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            display_name: Yup.string().required("Display name is required"),
          })}
          onSubmit={handleEditRole}
        >
          {(args) => (
            <form onSubmit={args.handleSubmit}>
              <Grid container spacing={2}>
                <RoleForm args={args} dataRole={dataRole} />

                {permissions && statusCheckbox && (
                  <PermissionRoleForm
                    args={args}
                    dataRole={dataRole}
                    permissions={result}
                    statusCheckbox={statusCheckbox}
                    setStatusCheckbox={setStatusCheckbox}
                  />
                )}

                <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                  <Box mt={2}>
                    <NavLink to="/vcms/role/list">
                      <MyButton text="Hủy" color="secondary" />
                    </NavLink>

                    <MyButton
                      type="submit"
                      text="Sửa"
                      color="success"
                      style={{ marginRight: 15 }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={messageCreateRole.status}>
          {messageCreateRole.message}
        </Alert>
      </Snackbar>
    </>
  );
}
