import _ from "lodash";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { getList, getListSuccess, getListError, createRole } from "./reducer";
import callGraphqlApi from "src/utils/callGraphqlApi";
import queryList, { createRoleRedux } from "./query";

function* watchGetList({ payload }) {
  const resGetList = yield call(callGraphqlApi, "getList", queryList, payload);
  const errors = _.get(resGetList, "errors", null);
  if (errors) {
    return yield put(getListError(_.get(errors, "[0].message", null)));
  }
  const data = _.get(resGetList, "data", null);
  if (data) {
    yield put(getListSuccess(_.get(data, "getRoleList", [])));
  }
}

function* watchCreateRole({ payload: { payload, callback } }) {
  const resGetList = yield call(
    callGraphqlApi,
    "createRole",
    createRoleRedux,
    payload
  );
  const errors = _.get(resGetList, "errors", null);

  if (callback) {
    if (errors) {
      yield put(getListError(_.get(errors, "[0].message", null)));
      return callback(getListError(_.get(errors, "[0].message", null)));
    }
    const data = _.get(resGetList, "data", null);
    if (data) {
      yield put(getListSuccess(_.get(data, "createRole", [])));
      return callback(getListSuccess(_.get(data, "createRole", [])));
    }
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(getList.type, watchGetList),
    takeEvery(createRole.type, watchCreateRole),
  ]);
};
