import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import MyButton from '../Button';

// Content trong Dialog
export default function SimpleDialog(props) {
    const { onClose, open, onSubmit } = props;

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {
        onSubmit();
    };

    return (
        <Dialog fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Thông báo</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">Bạn có chắc chắn muốn xóa?</Typography>
            </DialogContent>
            <DialogActions>
                <MyButton color="primary" text="Đồng ý" onClick={handleSubmit}></MyButton>
                <MyButton text="Hủy" onClick={handleClose} color="default"></MyButton>
            </DialogActions>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};
