import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  FormControl,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";

import PageTitle from "src/components/PageTitle";
import MyInput from "src/components/Input";
import MySelect from "src/components/Select";
import MyButton from "src/components/Button";

import { userList, languageList } from "./data";
import DefaultImg from "src/assets/images/file-upload.png";

// Icon
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "left",
    padding: "1.25rem",
    margin: "0 auto",
    boxShadow: "0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "42%",
    },
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "15px 0",
    },
  },
  // Button Upload
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  // Style Box
  styleBox: {
    borderRadius: "5px",
    border: "1px dashed grey",
    padding: "2rem",
  },
  inputField: {
    padding: 8,
  },
}));

// Fake Data
const typeData = [
  { value: 0, label: "Admin" },
  { value: 1, label: "Member" },
];

export default function UserAdd() {
  const classes = useStyles();

  const handleAddUser = (values, callback) => {
    console.log(values);
    console.log(document.getElementById("contained-button-file").value);
  };
  return (
    <>
      <PageTitle>Add User</PageTitle>
      <Box
        p={2}
        mt={2}
        style={{ background: "white", textAlign: "center" }}
        borderRadius={2}
      >
        <Formik
          initialValues={{
            email: "",
            name: "",
            type: "0",
            role: "",
            country: "vi",
            address: "",
            phone: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .required("Email is required"),
            name: Yup.string().required("Name is required"),
            type: Yup.string().required("Role is required"),
            address: Yup.string().required("Address is required"),
            phone: Yup.string()
              .matches(/^\d+$/, "Số điện thoại không hợp lệ")
              .required("Phone is required"),
            password: Yup.string().required("Password is required"),
          })}
          onSubmit={handleAddUser}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Box classes={{ root: classes.form }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <div className={classes.root}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          document
                            .getElementById("contained-button-file")
                            .click()
                        }
                      >
                        <img
                          style={{ width: "100%", objectFit: "cover" }}
                          src={DefaultImg}
                          id="img_logo"
                        />
                      </div>
                      <input
                        style={{ display: "none" }}
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          var output = document.getElementById("img_logo");
                          output.src = URL.createObjectURL(
                            event.target.files[0]
                          );
                          output.onload = function() {
                            URL.revokeObjectURL(output.src); // free memory
                          };
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          classes={{ root: classes.typo }}
                        >
                          Email
                        </Typography>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                          inputProps={{
                            className: classes.inputField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          classes={{ root: classes.typo }}
                        >
                          Họ và tên
                        </Typography>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.name}
                          variant="outlined"
                          inputProps={{
                            className: classes.inputField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          classes={{ root: classes.typo }}
                        >
                          Loại tài khoản
                        </Typography>
                        <TextField
                          error={Boolean(touched.type && errors.type)}
                          fullWidth
                          helperText={touched.type && errors.type}
                          name="type"
                          select
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.type}
                          variant="outlined"
                          inputProps={{
                            className: classes.inputField,
                          }}
                        >
                          <MenuItem value="0">Member</MenuItem>
                          <MenuItem value="1">Admin</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          classes={{ root: classes.typo }}
                        >
                          Role
                        </Typography>
                        <TextField
                          error={Boolean(touched.role && errors.role)}
                          fullWidth
                          helperText={touched.role && errors.role}
                          name="role"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.role}
                          variant="outlined"
                          inputProps={{
                            className: classes.inputField,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* ------------------------------ */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          classes={{ root: classes.typo }}
                        >
                          Mã vùng
                        </Typography>
                        <TextField
                          error={Boolean(touched.country && errors.country)}
                          fullWidth
                          helperText={touched.country && errors.country}
                          name="country"
                          select
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.country}
                          variant="outlined"
                          inputProps={{
                            className: classes.inputField,
                          }}
                        >
                          <MenuItem value="vi">+84</MenuItem>
                          <MenuItem value="au">+61</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          classes={{ root: classes.typo }}
                        >
                          Số điện thoại
                        </Typography>
                        <TextField
                          error={Boolean(touched.phone && errors.phone)}
                          fullWidth
                          helperText={touched.phone && errors.phone}
                          name="phone"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.phone}
                          variant="outlined"
                          inputProps={{
                            className: classes.inputField,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      name="address"
                      color="textSecondary"
                      classes={{ root: classes.typo }}
                    >
                      Địa chỉ
                    </Typography>
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.address}
                      variant="outlined"
                      inputProps={{
                        className: classes.inputField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      classes={{ root: classes.typo }}
                    >
                      Password
                    </Typography>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      inputProps={{
                        className: classes.inputField,
                      }}
                    />
                  </Grid>
                </Grid>

                <Box mt={2} textAlign="center">
                  <MyButton
                    type="submit"
                    text="Lưu"
                    color="success"
                    style={{ marginRight: 15 }}
                  />
                  <NavLink to="/vcms/user/list">
                    <MyButton text="Hủy" color="secondary" />
                  </NavLink>
                </Box>
              </form>
            </Box>
          )}
        </Formik>
      </Box>
    </>
  );
}
