import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MapIcon from "@material-ui/icons/Map";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import TonalityIcon from "@material-ui/icons/Tonality";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import MenuItem from "./MenuItem";

import { useSelector } from "react-redux";
import { authSelector } from "src/state/auth/reducer";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
};

const items = [
  {
    title: "Đơn hàng",
    icon: <AssignmentIcon />,
    children: [
      {
        title: "Danh mục",
        href: "/vcms/category/list",
      },
      {
        title: "Đơn hàng",
        href: "/vcms/order/list",
      },
      {
        title: "Trạng thái",
        href: "/vcms/order-status/list",
      },
      {
        title: "Hình thức thanh toán",
        href: "/vcms/payment-type/list",
      },
    ],
  },
  {
    title: "Đối tác vận chuyển",
    icon: <LocalShippingIcon />,
    children: [
      {
        title: "Đối tác vận chuyển",
        href: "/vcms/carrier/list",
      },
      {
        title: "Trạng thái ship",
        href: "/vcms/carrier-order-status/list",
      },
    ],
  },
  {
    title: "Tài nguyên",
    icon: <MapIcon />,
    children: [
      {
        title: "Tỉnh, thành phố",
        href: "/vcms/province/list",
      },
      {
        title: "Quận, huyện",
        href: "/vcms/district/list",
      },
      {
        title: "Xã, phường",
        href: "/vcms/ward/list",
      },
    ],
  },
  {
    title: "Users",
    icon: <PeopleIcon />,
    children: [
      {
        title: "Phân quyền",
        href: "/vcms/role/list",
      },
      {
        title: "User",
        href: "/vcms/user/list",
      },
    ],
  },
  {
    title: "Cấu hình",
    icon: <SettingsIcon />,
    children: [
      {
        title: "Cấu hình",
        href: "/vcms/setting/detail",
        icon: <TonalityIcon />,
      },
      {
        title: "Ngôn ngữ",
        href: "/vcms/language/list",
        icon: <GTranslateIcon />,
      },
      {
        title: "Menu",
        href: "/vcms/menu-group/list",
        icon: <FormatListBulletedIcon />,
      },
    ],
  },
  {
    title: "Messenger",
    icon: <EmailOutlinedIcon />,
  },
  {
    title: "Chứng thực tài khoản",
    icon: <VerifiedUserIcon />,
    href: "/vcms/verifyAccount",
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  menuList: {
    maxHeight: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "&:: -webkit - scrollbar - thumb: hover": {
      background: "#555",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const { data } = useSelector(authSelector);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={0} height="calc(100% - 140px)">
        <List classes={{ root: classes.menuList }}>
          {items.map((item, id) => (
            <MenuItem
              key={item.title + id}
              title={item.title}
              icon={item.icon}
              href={item.href}
              children={item.children}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
