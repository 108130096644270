import _ from "lodash";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { get, getSuccess, getError } from "./reducer";
import callGraphqlApi from "src/utils/callGraphqlApi";
import { getUserRedux } from "./query";

function* watchGetUser({ payload: { payload, callback } }) {
  const result = yield call(callGraphqlApi, "get", getUserRedux, payload);
  if (callback) {
    const errors = _.get(result, "errors", null);
    if (errors) {
      yield put(getError(_.get(errors, "[0].message", null)));
      return callback(getError(_.get(errors, "[0].message", null)));
    }

    const data = _.get(result, "data", null);
    if (data) {
      yield put(getSuccess(_.get(data, "getUser", [])));
      return callback(getSuccess(_.get(data, "getUser", [])));
    }
  }
}

export const rootSagas = function* rootSagas() {
  yield all([takeEvery(get.type, watchGetUser)]);
};
