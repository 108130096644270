export default `mutation login($email: String!, $password: String!){
  login(email: $email, password: $password) {
    user {
      id
      username
      email
      full_name
      country
      address
      image
      type
      created_at
      updated_at
      user_phone {
        nation_phone_code
        phone
      }
    }
    token
  }
}`;

export const loginWithToken = `mutation loginWithToken($token: String!){
  loginWithToken(token: $token, ) {
    user {
      id
      username
      email
      full_name
      country
      address
      image
      type
      created_at
      updated_at
      user_phone {
        nation_phone_code
        phone
      }
    }
    token
  }
}`

export const logOutRedux = `query logout{
  logout {
    status
  }
}`;

export const forgotPasswordRedux = `mutation forgotPassword($email: String!){
  forgotPassword(email: $email) {
    status
  }
}`;

export const resetPasswordRedux = `mutation resetPassword($email: String!, $forgotPasswordToken: String!, $password: String!){
  resetPassword(email: $email, forgotPasswordToken: $forgotPasswordToken, password: $password) {
    status
  }
}`;
