import React, { useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Cookies from "js-cookie";
import Page from "src/components/Page";

import { useDispatch, useSelector } from "react-redux";
import { getUser, authSelector } from "src/state/auth/reducer";
import { COOKIE_TOKEN_NAME } from "src/utils/constant";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  wrapperFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const [errorLogin, setErrorLogin] = useState("");

  const { data } = useSelector(authSelector);

  const login = (values, callback) => {
    dispatch(
      getUser({
        payload: {
          email: values.email,
          password: values.password,
        },
        callback: (res) => {
          if (res.error) {
            callback.setSubmitting(false);
            setErrorLogin(
              ["No user found with this login credentials."].includes(res.error)
                ? res.error
                : ""
            );
          }
          if (res?.data?.token) {
            Cookies.set(COOKIE_TOKEN_NAME, res?.data?.token);
            if (query.get("redirect")) navigate(query.get("redirect"), {replace: true})
            else 
            navigate("/vcms/order/list", { replace: true });
          }
        },
      })
    );
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "", // admin2021@yopmail.com
              password: "", // 123123123
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string()
                .max(255)
                .required("Password is required"),
            })}
            onSubmit={login}
            validate={(values) => {
              if (values.email !== "" || values.password !== "") {
                setErrorLogin("");
              }
              return;
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />

                <p>{errorLogin}</p>

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Box className={classes.wrapperFooter}>
                  {/* <Typography color="textSecondary" variant="body1">
                    Don&apos;t have an account?{" "}
                    <Link component={RouterLink} to="/register" variant="h6">
                      Sign up
                    </Link>
                  </Typography> */}

                  <Typography color="textSecondary" variant="body1">
                    <Link
                      component={RouterLink}
                      to="/forgotPassword"
                      variant="h6"
                    >
                      Forgot Password?
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
