import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUniqueId } from "src/utils/helper";
// Call Reducer
import {
  getList as getListProvince,
  provinceListSelector,
} from "src/state/province-list/reducer";
import {
  get as getProvince,
  provinceSelector,
} from "src/state/province-one/reducer";
import {
  get as getDistrict,
  districtSelector,
} from "src/state/district-one/reducer";
import {
  orderStatusListSelector,
  getList as getOrderStatus,
} from "src/state/order-status-list/reducer";
import {
  categoryDescriptionListSelector,
  getList as getCategoryDescription,
} from "src/state/category-description-list/reducer";

import {
  paymentTypeListSelector,
  getList as getPaymentType,
} from "src/state/payment-type-list/reducer";

import {
  getFee,
  getOrderReducer,
  createOrder,
  updateOrder,
} from "src/state/order-one/reducer";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  colors,
} from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ErrorIcon from "@material-ui/icons/Error";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Checkbox from "@material-ui/core/Checkbox";

import PageTitle from "src/components/PageTitle";
import MyInput from "src/components/Input";
import MySelect from "src/components/Select";
import MyButton from "src/components/Button";

import { currencyList, deliveryList } from "src/utils/constant";

import { appSelector } from "src/state/app/reducer";
import { formatInputGetFee } from "src/utils/helper";

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "left",
    padding: "1.25rem",
    boxShadow: "0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "42%",
    },
  },
  formFull: {
    textAlign: "left",
    padding: "1.25rem",
    boxShadow: "0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)",
    width: "100%",
    margin: "20px 0",
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
    },
  },
  typoHead: {
    background: "#efefef",
    padding: 10,
    textAlign: "center",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
    },
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: 5,
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "&:: -webkit - scrollbar - thumb: hover": {
      background: "#555",
    },
  },
  myHead: {
    background: "#eee",
  },
  myCell: {
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  myCellLast: {
    border: "none !important",
  },
  myCellAction: {
    border: "1px solid rgba(224, 224, 224, 1) !important",
    width: "50px !important",
    textAlign: "center",
  },
}));

// valueDefault
const orderStatusDefault = {
  id: "0",
  value: "0",
  label: "Chọn trạng thái",
};
const categoryDescriptionDefault = {
  id: "0",
  value: "0",
  label: "Chọn danh mục",
};
const provinceDefault = {
  id: "0",
  value: "0",
  label: "Chọn tỉnh, thành phố",
};
const districtDefault = {
  id: "0",
  value: "0",
  label: "Chọn huyện, quận",
};
const wardDefault = {
  id: "0",
  value: "0",
  label: "Chọn xã, phường",
};
const paymentTypeDefault = {
  id: "0",
  value: "0",
  label: "Chọn hình thức thanh toán",
};
const productDefault = {
  id: getUniqueId(),
  category: categoryDescriptionDefault,
  nameProduct: "",
  totalWeight: 0,
  totalLenght: 0,
  totalPrice: 0,
  focused: {
    nameProduct: false,
    totalWeight: false,
    totalLenght: false,
    totalPrice: false,
    category: false,
  },
};

export default function OrderAdd() {
  const dispatch = useDispatch();
  const { device } = useSelector(appSelector);
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  // Data
  const { data: provinceList, error: errorProvinceList } = useSelector(
    provinceListSelector
  );
  const { data: province } = useSelector(provinceSelector);
  const { data: district } = useSelector(districtSelector);
  const { data: orderStatus } = useSelector(orderStatusListSelector);
  const { data: categoryDescription } = useSelector(
    categoryDescriptionListSelector
  );
  const { data: paymentType } = useSelector(paymentTypeListSelector);
  // Default when map
  const [note, setNote] = useState();
  const [pickup, setPickup] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [pickupContent, setPickupContent] = useState();

  const [sendSydney, setSendSydney] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState();
  const [orderCode, setOrderCode] = useState();

  const [provinceSelected, setProvinceSelected] = useState(provinceDefault);
  const [districtSelected, setDistrictSelected] = useState(districtDefault);
  const [wardSelected, setWardSelected] = useState(wardDefault);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [length, setLength] = useState(1);
  const [weight, setWeight] = useState();
  const [totalCount, setTotalCount] = useState(0);

  const [currency, setCurrency] = useState(currencyList[0]);
  const [delivery, setDelivery] = useState(deliveryList[0]);

  const [productArray, setProductArray] = useState([productDefault]);

  const [orderStatusSelected, setOrderStatusSelected] = useState(
    orderStatusDefault
  );
  const [categoryDescriptionSelect, setCategoryDescriptionSelected] = useState(
    categoryDescriptionDefault
  );
  const [paymentTypeSeclect, setPaymentTypeSelected] = useState(
    paymentTypeDefault
  );

  const [standardFee, setStandardFee] = useState(0);
  const [expressFee, setExpressFee] = useState(0);
  const [surcharge, setSurcharge] = useState(0);

  const [temFee, setTemFee] = useState(0);
  const [subFee, setSubFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);

  const [orderId, setOrderId] = useState(getUniqueId().toUpperCase());

  const [checkSelected, setCheckSelected] = useState();
  const [orderStatusLogs, setOrderStatusLogs] = useState([]);

  const [codeGHN, setCodeGHN] = useState();
  const [location, setLocation] = useState();
  const [nameSend, setNameSend] = useState();
  const [phoneSend, setPhoneSend] = useState();
  const [emailSend, setEmailSend] = useState();
  const [addressSend, setAddressSend] = useState();
  const [nameTo, setNameTo] = useState();
  const [phoneTo, setPhoneTo] = useState();
  const [emailTo, setEmailTo] = useState();
  const [addressTo, setAddressTo] = useState();

  const [price, setPrice] = useState();
  const [check, setCheck] = useState();

  // State Radio
  const [value, setStateRadio] = useState("2");

  const handleChangeRadio = (event) => {
    if (event.target.value === "1") {
      setTemFee(standardFee);
      console.log(parseFloat(standardFee) + parseFloat(subFee));
      setTotalFee(parseFloat(standardFee) + parseFloat(subFee));
    }
    if (event.target.value === "2") {
      setTemFee(expressFee);
      console.log(parseFloat(expressFee) + parseFloat(subFee));
      setTotalFee(parseFloat(expressFee) + parseFloat(subFee));
    }
    setStateRadio(event.target.value);
  };

  // Checkbox
  const [valueCheckbox, setValueCheckbox] = React.useState();

  const handleChangeCheckbox = (event) => {
    setValueCheckbox(event.target.value);
  };

  // setTransportFee(standardFee+expressFee)
  // Call action getList

  // Handle Dialog
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // Handle Dialog Valid
  const [openValid, setOpenValid] = React.useState(false);

  const handleCloseValid = () => {
    setOpenValid(false);
  };

  // Handle Dialog Error
  const [openError, setOpenError] = React.useState(false);

  const handleCloseError = () => {
    setOpenError(false);
  };

  // Handle Dialog Success
  const [openSuccess, setOpenSuccess] = React.useState(false);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  useEffect(() => {
    dispatch(
      getListProvince({
        status: "active",
        language_code: "vi",
        limit: 100,
        offset: 0,
      })
    );
    dispatch(
      getOrderStatus({
        status: "active",
        language_code: "vi",
        limit: 100,
        offset: 0,
      })
    );
    dispatch(
      getCategoryDescription({
        language_code: "vi",
        limit: 100,
        offset: 0,
      })
    );
    dispatch(
      getPaymentType({
        status: "active",
        language_code: "vi",
        limit: 100,
        offset: 0,
      })
    );
    dispatch(
      getOrderReducer({
        payload: id,
        callback: (e) => {
          if (e.data) {
            setCodeGHN(e.data.code_carrier);
            setLocation(e.data.location);
            setNameSend(e.data.order_send.name);
            setPhoneSend(e.data.order_send.phone);
            setEmailSend(e.data.order_send.email);
            setAddressSend(e.data.order_send.address);
            setNameTo(e.data.order_recipient.name);
            setPhoneTo(e.data.order_recipient.phone);
            setEmailTo(e.data.order_recipient.email);
            setAddressTo(e.data.order_recipient.address);
            setOrderId(e.data.code);
            setPrice(e.data.price);
            setTotalCount(e.data.bales);
            setWidth(e.data.width);
            setHeight(e.data.height);
            setLength(e.data.length);
            setWeight(e.data.weight);
            setExpressFee(e.data.config_fee_ship);
            setSurcharge(e.data.surcharge);
            setOrderStatusSelected({
              id: e.data.order_status.id,
              value: e.data.order_status.id,
              label: e.data.order_status.name,
            });
            setOrderStatusLogs(e.data.order_status_logs);

            setProductArray(
              e.data.order_products.map((item) => ({
                id: item.id,
                category: {
                  id: item.category.id,
                  value: item.category.id,
                  label: item.category.description.title,
                },
                nameProduct: item.product_name,
                totalWeight: item.weight,
                totalLenght: item.amount,
                totalPrice: item.price,
                focused: {
                  nameProduct: false,
                  totalWeight: false,
                  totalLenght: false,
                  totalPrice: false,
                  category: false,
                },
              }))
            );
            setPaymentTypeSelected({
              id: e.data.payment_type.id,
              value: e.data.payment_type.id,
              label: e.data.payment_type.name,
            });
            setCurrency(
              currencyList.find((item) => item.value === e.data.currency)
            );
            setNote(e.data.note);
            if (e.data.pickup) {
              setPickup(true);
              setPickupContent(e.data.pickup_content);
              setSelectedDate(new Date(e.data.pickup_date));
            }
            if (e.data.warehouse_sydney) {
              setSendSydney(true);
              setTrackingNumber(e.data.warehouse_sydney_tracking);
              setOrderCode(e.data.warehouse_sydney_order);
            }
            setTotalFee(e.data.fee);
            setStateRadio(JSON.stringify(e.data.service_type_id));

            handleGetFeeInit(e);
            setProvinceSelected({
              id: e.data.order_recipient.province.id,
              value: e.data.order_recipient.province.id,
              label: e.data.order_recipient.province.description.name,
            });
            setDistrictSelected({
              id: e.data.order_recipient.district.id,
              value: e.data.order_recipient.district.id,
              label: e.data.order_recipient.district.description.name,
              ghn_id: e.data.order_recipient.district.ghn_id,
            });
            setWardSelected({
              id: e.data.order_recipient.ward.id,
              value: e.data.order_recipient.ward.id,
              label: e.data.order_recipient.ward.description.name,
              ghn_id: e.data.order_recipient.ward.ghn_id,
            });
          }
        },
      })
    );
  }, []);

  const handleGetFeeInit = (e) => {
    dispatch(
      getFee({
        payload: formatInputGetFee({
          service_type_id: e.data.service_type_id,
          currency: e.data.currency,
          to_district_id: e.data.order_recipient.district.ghn_id,
          to_ward_code: e.data.order_recipient.ward.ghn_id,
          height: e.data.height,
          length: e.data.length,
          width: e.data.width,
          weight: e.data.weight,
          insurance_value: 0,
          coupon: "",
        }),
        callback: (res) => {
          setStandardFee(res.payload[0].fee);
          setExpressFee(res.payload[1].fee);
          if (e.data.service_type_id === 2) {
            setTemFee(res.payload[0].fee);
            setSubFee(parseFloat(e.data.fee) - parseFloat(res.payload[0].fee));
          }
          if (e.data.service_type_id === 1) {
            setSubFee(parseFloat(e.data.fee) - parseFloat(res.payload[1].fee));
            setTemFee(res.payload[1].fee);
          }
        },
      })
    );
  };

  // Status
  const handleChangeOrderStatus = (e) => {
    if (e.target.value === "0")
      return setOrderStatusSelected(orderStatusDefault);
    const selected = orderStatus.find((item) => item.id === e.target.value);

    setOrderStatusSelected({
      id: selected.id,
      value: selected.id,
      label: selected.name,
    });
  };

  useEffect(() => {
    setCheckSelected(orderStatusSelected);
  }, [orderStatusSelected]);

  // Payment Type
  const handleChangePaymentType = (e) => {
    if (e.target.value === "0")
      return setPaymentTypeSelected(paymentTypeDefault);
    const selected = paymentType.find((item) => item.id === e.target.value);

    setPaymentTypeSelected({
      id: selected.id,
      value: selected.id,
      label: selected.name,
    });
  };

  useEffect(() => {
    if (provinceSelected && provinceSelected.id !== "0") {
      dispatch(
        getProvince({
          id: parseInt(provinceSelected.id),
        })
      );
    }
  }, [provinceSelected]);

  useEffect(() => {
    if (districtSelected && districtSelected.id !== "0") {
      dispatch(
        getDistrict({
          id: parseInt(districtSelected.id),
        })
      );
    }
  }, [districtSelected]);

  const handleChangeProvince = (e) => {
    if (e.target.value === "0") return setProvinceSelected(provinceDefault);
    const selected = provinceList.find((item) => item.id === e.target.value);

    setProvinceSelected({
      id: selected.id,
      value: selected.id,
      label: selected.description.name,
    });
  };

  const handleChangeDistrict = (e) => {
    if (e.target.value === "0") return setDistrictSelected(districtDefault);
    const selected = province.districts.find(
      (item) => item.id === e.target.value
    );
    setDistrictSelected({
      id: selected.id,
      value: selected.id,
      label: selected.description.name,
      ghn_id: selected.ghn_id,
    });
  };

  const handleChangeWard = (e) => {
    if (e.target.value === "0") return setWardSelected(districtDefault);
    const selected = district.wards.find((item) => item.id === e.target.value);
    setWardSelected({
      id: selected.id,
      value: selected.id,
      label: selected.description.name,
      ghn_id: selected.ghn_id,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleGetFee = () => {
    if (
      width <= 0 ||
      height <= 0 ||
      weight <= 0 ||
      length <= 0 ||
      provinceSelected === provinceDefault ||
      districtSelected === districtDefault ||
      wardSelected === wardDefault ||
      categoryDescription === categoryDescriptionDefault ||
      check === "invalid" ||
      paymentTypeSeclect === paymentTypeDefault
    ) {
      setOpen(true);
    } else {
      dispatch(
        getFee({
          payload: formatInputGetFee({
            service_type_id: value,
            currency: currency.value,
            to_district_id: districtSelected.ghn_id,
            to_ward_code: wardSelected.ghn_id,
            height,
            length,
            width,
            weight,
            insurance_value: 0,
            coupon: "",
          }),
          callback: (res) => {
            setStandardFee(res.payload[0].fee);
            setExpressFee(res.payload[1].fee);
            if (parseInt(value) === 2) {
              setTemFee(res.payload[0].fee);
              setTotalFee(res.payload[0].fee + parseFloat(subFee));
            }
            if (parseInt(value) === 1) {
              setTemFee(res.payload[1].fee);
              setTotalFee(res.payload[1].fee + parseFloat(subFee));
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    if (value === "2") {
      setTemFee(standardFee);
      setTotalFee(parseFloat(standardFee) + parseFloat(subFee));
    }
    if (value === "1") {
      setTemFee(expressFee);
      setTotalFee(parseFloat(expressFee) + parseFloat(subFee));
    }
  }, [value]);

  // Handle Add
  const handleAdd = () => {
    setProductArray([
      ...productArray,
      {
        ...productDefault,
        id: getUniqueId(),
      },
    ]);
  };

  const handleRemove = (id) => () => {
    if (productArray.length === 1) {
      return setOpen(true);
    }

    // newWeight, map newArray + newWeight => newWeight = Weight => setWeight => set Array(newArraay)
    let newArray = productArray.filter((item) => item.id !== id);

    let newWeight = 0,
      newPrice = 0;

    newArray.map((item) => {
      newWeight = newWeight + parseFloat(item.totalWeight);
      newPrice = newPrice + parseFloat(item.totalPrice);
    });

    setWeight(newWeight);
    setPrice(newPrice);
    setProductArray(newArray);

    // setOrderProducts(orderProducts.filter((item) => item.id !== id));
  };

  // Handle Change Input
  const handleChangeInput = (name, id, field) => (e) => {
    const arrayNewTest = productArray.map((item) => {
      if (id !== item.id) {
        return item;
      }
      if (name === "focused") {
        return {
          ...item,
          focused: {
            ...item.focused,
            [field]: true,
          },
        };
      }
      if (name === "category") {
        if (e.target.value === "0")
          return {
            ...item,
            category: categoryDescriptionDefault,
          };
        const selected = categoryDescription.find(
          (item) => item.category_id === e.target.value
        );

        return {
          ...item,
          category: {
            id: selected.category_id,
            value: selected.category_id,
            label: selected.title,
          },
        };
      }
      return {
        ...item,
        [name]: e.target.value,
      };
    });

    if (name === "totalWeight") {
      let totalWeight = 0;

      arrayNewTest.map(
        (item) => (totalWeight = totalWeight + parseFloat(item.totalWeight))
      );
      setWeight(totalWeight);
    }
    if (name === "totalPrice") {
      let totalPrice = 0;

      arrayNewTest.map(
        (item) => (totalPrice = totalPrice + parseFloat(item.totalPrice))
      );
      setPrice(totalPrice);
    }

    setProductArray(arrayNewTest);
  };

  const handleValid = () => {
    productArray.map((item) => {
      if (
        item.nameProduct === "" ||
        item.totalWeight <= 0 ||
        item.totalPrice <= 0 ||
        item.totalLength <= 0 ||
        item.category === categoryDescriptionDefault
      ) {
        setCheck("invalid");
      } else {
        setCheck("valid");
      }
    });

    if (
      !nameSend ||
      !phoneSend ||
      !emailSend ||
      !addressSend ||
      !nameTo ||
      !phoneTo ||
      !emailTo ||
      !addressTo ||
      width <= 0 ||
      height <= 0 ||
      weight <= 0 ||
      length <= 0 ||
      orderStatusSelected === orderStatusDefault ||
      standardFee === 0 ||
      expressFee === 0 ||
      totalCount === 0 ||
      (orderStatusSelected.id === "18" && !codeGHN) ||
      (sendSydney && !trackingNumber) ||
      (sendSydney && !orderCode) ||
      provinceSelected === provinceDefault ||
      districtSelected === districtDefault ||
      wardSelected === wardDefault ||
      categoryDescription === categoryDescriptionDefault ||
      check === "invalid" ||
      paymentTypeSeclect === paymentTypeDefault
    ) {
      setOpenValid(!openValid);
    } else {
      dispatch(
        updateOrder({
          payload: {
            id,
            order_status_id: parseInt(orderStatusSelected.id),
            code_carrier: codeGHN,
            order_send: {
              name: nameSend,
              phone: phoneSend,
              email: emailSend,
              country: "au",
              address: addressSend,
            },
            order_recipient: {
              name: nameTo,
              phone: phoneTo,
              email: emailTo,
              province_id: parseInt(provinceSelected.value),
              district_id: parseInt(districtSelected.value),
              ward_id: parseInt(wardSelected.value),
              address: addressTo,
            },
            code: orderId,
            price: parseFloat(price).toFixed(2),
            width: parseFloat(width).toFixed(2),
            height: parseFloat(height).toFixed(2),
            weight: parseFloat(weight).toFixed(2),
            length: parseFloat(length).toFixed(2),
            payment_type_id: parseInt(paymentTypeSeclect.value),
            bales: parseInt(totalCount),
            currency: currency.value,
            fee: parseFloat(totalFee).toFixed(2),
            service_type_id: parseInt(value),
            order_products: productArray.map((item) => ({
              category_id: parseInt(item.category.value),
              product_name: item.nameProduct,
              amount: parseInt(item.totalLenght),
              weight: parseFloat(item.totalWeight),
              price: parseFloat(item.totalPrice),
            })),
            note,
            pickup_date: selectedDate,
            pickup_content: pickupContent,
            warehouse_sydney_order: orderCode,
            warehouse_sydney_tracking: trackingNumber,
            pickup: pickup ? 1 : 0,
            warehouse_sydney: sendSydney ? 1 : 0,
            location,
            surcharge: parseFloat(0).toFixed(2),
            to_district_id: parseInt(districtSelected.ghn_id),
            to_ward_code: wardSelected.ghn_id,
          },
          callback: (res) => {
            if (res.error) {
              console.error(res.error);
              setOpenError(!openError);
            }
            if (res.data) {
              setOpenSuccess(!openSuccess);
              // navigate("/vcms/order/list", { replace: true });
            }
            return;
          },
        })
      );
    }
  };

  return (
    <>
      <PageTitle>Edit Order</PageTitle>
      <Box
        p={2}
        mt={2}
        style={{ background: "white", textAlign: "center" }}
        borderRadius={2}
      >
        <Box mt={2} textAlign="right">
          <MyButton
            text="Lưu"
            color="success"
            style={{ marginRight: 15 }}
            onClick={handleValid}
          />
          <NavLink to="/vcms/order/list">
            <MyButton text="Hủy" color="secondary" />
          </NavLink>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box classes={{ root: classes.formFull }}>
              <Typography
                variant="h5"
                color="default"
                classes={{ root: classes.typoHead }}
              >
                Trạng thái đơn hàng
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Tình trạng đơn hàng
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>
                  <MySelect
                    options={[orderStatusDefault].concat(
                      (orderStatus || []).map((item) => ({
                        id: item.id,
                        value: item.id,
                        label: item.name,
                      }))
                    )}
                    value={orderStatusSelected.value}
                    onChange={handleChangeOrderStatus}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  {checkSelected?.id === "18" ? (
                    <Box display="flex">
                      <Typography variant="h6" color="default">
                        Mã đơn hàng GHN
                      </Typography>
                      <Typography color="error">(*)</Typography>
                    </Box>
                  ) : (
                    <Typography variant="h6" color="default">
                      Mã đơn hàng GHN
                    </Typography>
                  )}

                  <MyInput
                    id="orderId"
                    placeholder=""
                    style={{ width: "100% !important" }}
                    value={codeGHN}
                    onChange={(e) => setCodeGHN(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h6" color="default">
                    Vị trí
                  </Typography>

                  <MyInput
                    id="positionId"
                    placeholder=""
                    style={{ width: "100% !important" }}
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box classes={{ root: classes.formFull }}>
              <Typography
                variant="h5"
                color="default"
                classes={{ root: classes.typoHead }}
              >
                Lịch sử cập nhật
              </Typography>
              <Box
                classes={{ root: classes.formFull }}
                style={{ height: 200, overflow: "auto" }}
              >
                <List>
                  {orderStatusLogs?.map((item) => (
                    <ListItem button>
                      <ListItemIcon>
                        {[11, 17, 22, 24, 25, 26, 27, 28].includes(
                          parseInt(item.order_status.id)
                        ) && (
                          <DoneAllIcon style={{ color: colors.green[500] }} />
                        )}
                        {[1, 15, 18, 19, 20, 21, 23, 29, 30, 31].includes(
                          parseInt(item.order_status.id)
                        ) && <LoopIcon color="primary" />}
                        {[2, 3, 5, 7].includes(
                          parseInt(item.order_status.id)
                        ) && <ErrorIcon color="error" />}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography>
                            <b>
                              User:&ensp; {item.user?.email}{" "}
                              {item.log ? item.log : ""}
                            </b>
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography>
                              <b>Trạng thái:</b>&ensp;{item.order_status.name}
                            </Typography>
                            <Typography>
                              <b>Created:</b>&ensp;{item.created_at}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box classes={{ root: classes.formFull }}>
              <Typography
                variant="h5"
                color="default"
                classes={{ root: classes.typoHead }}
              >
                Thông tin người gửi
              </Typography>
              <Box display="flex">
                <Typography variant="h6" color="default">
                  Họ tên&ensp;
                </Typography>
                <Typography color="error">(*)</Typography>
              </Box>
              <MyInput
                id="name1"
                placeholder=""
                value={nameSend}
                onChange={(e) => setNameSend(e.target.value)}
              />
              <Box display="flex">
                <Typography variant="h6" color="default">
                  Số điện thoại&ensp;
                </Typography>
                <Typography color="error">(*)</Typography>
              </Box>

              <MyInput
                id="std1"
                placeholder=""
                value={phoneSend}
                onChange={(e) => setPhoneSend(e.target.value)}
              />
              <Box display="flex">
                <Typography variant="h6" color="default">
                  Email&ensp;
                </Typography>
                <Typography color="error">(*)</Typography>
              </Box>

              <MyInput
                id="emal1"
                placeholder=""
                value={emailSend}
                onChange={(e) => setEmailSend(e.target.value)}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Typography variant="h6" color="default">
                    Quốc gia
                  </Typography>
                  <MySelect
                    label="Chọn quốc gia"
                    variant="outlined"
                    options={[{ id: 1, value: 1, label: "Úc" }]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Địa chỉ&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>

                  <MyInput
                    id="dc1"
                    placeholder=""
                    value={addressSend}
                    onChange={(e) => setAddressSend(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box classes={{ root: classes.formFull }}>
              <Typography
                variant="h5"
                color="default"
                classes={{ root: classes.typoHead }}
              >
                Thông tin người nhận
              </Typography>
              <Box display="flex">
                <Typography variant="h6" color="default">
                  Họ tên&ensp;
                </Typography>
                <Typography color="error">(*)</Typography>
              </Box>

              <MyInput
                id="name2"
                placeholder=""
                value={nameTo}
                onChange={(e) => setNameTo(e.target.value)}
              />
              <Box display="flex">
                <Typography variant="h6" color="default">
                  Số điện thoại&ensp;
                </Typography>
                <Typography color="error">(*)</Typography>
              </Box>

              <MyInput
                id="ps2"
                placeholder=""
                value={phoneTo}
                onChange={(e) => setPhoneTo(e.target.value)}
              />
              <Box display="flex">
                <Typography variant="h6" color="default">
                  Email&ensp;
                </Typography>
                <Typography color="error">(*)</Typography>
              </Box>

              <MyInput
                id="mail2"
                placeholder=""
                value={emailTo}
                onChange={(e) => setEmailTo(e.target.value)}
              />
              <Box display="flex">
                <Typography variant="h6" color="default">
                  Địa chỉ&ensp;
                </Typography>
                <Typography color="error">(*)</Typography>
              </Box>

              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <MySelect
                    variant="outlined"
                    options={[provinceDefault].concat(
                      (provinceList || []).map((item) => ({
                        id: item.id,
                        value: item.id,
                        label: item.description.name,
                      }))
                    )}
                    value={provinceSelected.value}
                    onChange={handleChangeProvince}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <MySelect
                    variant="outlined"
                    disable={!province}
                    options={[districtDefault].concat(
                      ((province && province.districts) || []).map((item) => ({
                        id: item.id,
                        value: item.id,
                        label: item.description.name,
                      }))
                    )}
                    value={districtSelected.value}
                    onChange={handleChangeDistrict}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <MySelect
                    variant="outlined"
                    disable={!district}
                    options={[wardDefault].concat(
                      ((district && district.wards) || []).map((item) => ({
                        id: item.id,
                        value: item.id,
                        label: item.description.name,
                      }))
                    )}
                    value={wardSelected.value}
                    onChange={handleChangeWard}
                  />
                </Grid>
              </Grid>
              <Box display="flex">
                <Typography variant="h6" color="default">
                  Địa chỉ chi tiết&ensp;
                </Typography>
                <Typography color="error">(*)</Typography>
              </Box>
              <Grid container>
                <Grid item xs={12}>
                  <MyInput
                    id="none"
                    placeholder=""
                    style={{ width: "100%", marginTop: 20 }}
                    value={addressTo}
                    onChange={(e) => setAddressTo(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box classes={{ root: classes.formFull }}>
          <Typography
            variant="h5"
            color="default"
            classes={{ root: classes.typoHead }}
          >
            Sản phẩm
          </Typography>
          <TableContainer classes={{ root: classes.tableContainer }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead classes={{ root: classes.myHead }}>
                <TableRow>
                  <TableCell classes={{ root: classes.myCell }}>
                    Danh mục
                  </TableCell>
                  <TableCell classes={{ root: classes.myCell }}>
                    Sản phẩm
                  </TableCell>
                  <TableCell classes={{ root: classes.myCell }}>
                    Tổng Khối lượng
                  </TableCell>
                  <TableCell classes={{ root: classes.myCell }}>
                    Số lượng
                  </TableCell>
                  <TableCell classes={{ root: classes.myCell }}>
                    Tổng Giá
                  </TableCell>
                  <TableCell classes={{ root: classes.myCellAction }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productArray.map((item, index) => {
                  return (
                    <TableRow>
                      <TableCell classes={{ root: classes.myCell }}>
                        <MySelect
                          label="---Chọn---"
                          variant="outlined"
                          options={[categoryDescriptionDefault].concat(
                            (categoryDescription || []).map((item) => ({
                              id: item.category_id,
                              value: item.category_id,
                              label: item.title,
                            }))
                          )}
                          value={item.category.value}
                          onChange={handleChangeInput("category", item.id)}
                          onBlur={handleChangeInput(
                            "focused",
                            item.id,
                            "category"
                          )}
                        />
                        {item.focused["category"] &&
                          item.category.id ===
                            categoryDescriptionDefault.id && (
                            <Typography color="error">Không hợp lệ</Typography>
                          )}
                      </TableCell>
                      <TableCell classes={{ root: classes.myCell }}>
                        <MyInput
                          id="c1"
                          placeholder=""
                          key="orderId"
                          style={{ width: "100% !important" }}
                          value={item.nameProduct}
                          onChange={handleChangeInput("nameProduct", item.id)}
                          onBlur={handleChangeInput(
                            "focused",
                            item.id,
                            "nameProduct"
                          )}
                        />
                        {item.focused["nameProduct"] &&
                          item.nameProduct === productDefault.nameProduct && (
                            <Typography color="error">Không hợp lệ</Typography>
                          )}
                      </TableCell>
                      <TableCell classes={{ root: classes.myCell }}>
                        <MyInput
                          id="c2"
                          placeholder=""
                          key="orderId"
                          style={{ width: "100% !important" }}
                          type="number"
                          min="0"
                          value={item.totalWeight}
                          onChange={handleChangeInput("totalWeight", item.id)}
                          onBlur={handleChangeInput(
                            "focused",
                            item.id,
                            "totalWeight"
                          )}
                        />
                        {item.focused["totalWeight"] &&
                          (item.totalWeight === productDefault.totalWeight ||
                            item.totalWeight === "") && (
                            <Typography color="error">Không hợp lệ</Typography>
                          )}
                      </TableCell>
                      <TableCell classes={{ root: classes.myCell }}>
                        <MyInput
                          id="c3"
                          placeholder=""
                          key="orderId"
                          type="number"
                          min="0"
                          style={{ width: "100% !important" }}
                          value={item.totalLenght}
                          onChange={handleChangeInput("totalLenght", item.id)}
                          onBlur={handleChangeInput(
                            "focused",
                            item.id,
                            "totalLength"
                          )}
                        />
                        {item.focused["totalLength"] &&
                          (item.totalLenght === productDefault.totalLenght ||
                            item.totalLenght === "") && (
                            <Typography color="error">Không hợp lệ</Typography>
                          )}
                      </TableCell>
                      <TableCell classes={{ root: classes.myCell }}>
                        <MyInput
                          id="c4"
                          placeholder=""
                          key="orderId"
                          type="number"
                          min="0"
                          style={{ width: "100% !important" }}
                          value={item.totalPrice}
                          onChange={handleChangeInput("totalPrice", item.id)}
                          onBlur={handleChangeInput(
                            "focused",
                            item.id,
                            "totalPrice"
                          )}
                        />
                        {item.focused["totalPrice"] &&
                          (item.totalPrice === productDefault.totalPrice ||
                            item.totalPrice === "") && (
                            <Typography color="error">Không hợp lệ</Typography>
                          )}
                      </TableCell>
                      <TableCell classes={{ root: classes.myCellAction }}>
                        <MyButton
                          variant="contained"
                          color="secondary"
                          onClick={handleRemove(item.id)}
                        >
                          <DeleteIcon />
                        </MyButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell classes={{ root: classes.myCellAction }}>
                    <MyButton
                      variant="contained"
                      color="success"
                      onClick={handleAdd}
                    >
                      <AddCircleIcon />
                    </MyButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box classes={{ root: classes.formFull }}>
              <Typography
                variant="h5"
                color="default"
                classes={{ root: classes.typoHead }}
              >
                Thông tin thanh toán
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Mã tờ khai&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>
                  <MyInput id="mtk" placeholder="" disabled value={orderId} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Giá trị gửi hàng&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>
                  <MyInput
                    id="gtgh"
                    placeholder=""
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Hình thức thanh toán&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>
                  <MySelect
                    label="Chọn hình thức thanh toán"
                    variant="outlined"
                    options={[paymentTypeDefault].concat(
                      (paymentType || []).map((item) => ({
                        id: item.id,
                        value: item.id,
                        label: item.name,
                      }))
                    )}
                    value={paymentTypeSeclect.value}
                    onChange={handleChangePaymentType}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Width (cm)&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>

                  <MyInput
                    id="wid"
                    placeholder=""
                    type="number"
                    min="0"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Height (cm)&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>
                  <MyInput
                    id="htei"
                    placeholder=""
                    type="number"
                    min="0"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Length (cm)&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>

                  <MyInput
                    id="leng"
                    placeholder=""
                    type="number"
                    min="0"
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Tổng số kiện&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>

                  <MyInput
                    id="tongsk"
                    placeholder=""
                    type="number"
                    min="0"
                    value={totalCount}
                    onChange={(e) => setTotalCount(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Box display="flex">
                    <Typography variant="h6" color="default">
                      Tổng kg (ước tính)&ensp;
                    </Typography>
                    <Typography color="error">(*)</Typography>
                  </Box>

                  <MyInput id="tongut" placeholder="" value={weight} disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Typography variant="h6" color="default">
                    Loại tiền tề
                  </Typography>
                  <MySelect
                    label="Chọn loại tiền"
                    variant="outlined"
                    options={currencyList}
                    value={currency.value}
                    onChange={(e) =>
                      setCurrency(
                        currencyList.find(
                          (item) => item.value === e.target.value
                        )
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Typography variant="h6" color="default">
                    Phí vận chuyển (tạm tính) {currency.value}
                  </Typography>
                  <RadioGroup value={value} onChange={handleChangeRadio}>
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label={
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography variant="h6" color="textSecondary">
                              Standard
                            </Typography>
                          </Grid>
                          <Grid item>
                            <b>
                              {standardFee} {currency.value}
                            </b>
                          </Grid>
                        </Grid>
                      }
                    />
                    <Typography variant="h6" color="default">
                      Thời gian nhận hàng 10 đến 20 ngày
                    </Typography>
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography variant="h6" color="textSecondary">
                              Express
                            </Typography>
                          </Grid>
                          <Grid item>
                            <b>
                              {expressFee} {currency.value}
                            </b>
                          </Grid>
                        </Grid>
                      }
                    />
                    <Typography variant="h6" color="default">
                      Thời gian nhận hàng 7 đến 10 ngày
                    </Typography>
                  </RadioGroup>
                </Grid>
              </Grid>
              <Box mt={1} mb={1}>
                <MyButton
                  text="Get fee"
                  color="default"
                  style={{ width: "100px !important" }}
                  onClick={handleGetFee}
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Typography variant="h6" color="default">
                    Phí vận chuyển (tạm tính)
                  </Typography>
                  <MyInput id="phi" placeholder="" disabled value={temFee} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Typography variant="h6" color="default">
                    Phụ phí
                  </Typography>
                  <MyInput id="phuphi" placeholder="" disabled value={subFee} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Typography variant="h6" color="default">
                    Tổng thanh toán (tạm tính)
                  </Typography>
                  <MyInput id="tong" placeholder="" disabled value={totalFee} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box classes={{ root: classes.formFull }}>
              <Typography
                variant="h5"
                color="default"
                classes={{ root: classes.typoHead }}
              >
                Thông tin khác
              </Typography>
              <Typography variant="h6" color="default">
                Ghi chú
              </Typography>
              <MyInput
                id="ghi chu"
                placeholder=""
                rows={5}
                rowsMax={5}
                multiline
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <Box mt={1} display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  checked={pickup}
                  onChange={() => {
                    setPickup(!pickup);
                    if (!pickup) setSendSydney(false);
                  }}
                />
                <Typography variant="body1" color="default">
                  Yêu cầu pickup tại nhà?
                </Typography>
              </Box>
              {pickup && (
                <>
                  <Typography variant="h6" color="default">
                    Ngày pickup hàng
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="MM/dd/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Typography variant="h6" color="default">
                    Nội dung hàng pickup
                  </Typography>
                  <MyInput
                    id="ghi chu"
                    placeholder={`Lưu ý: Công ty chỉ pickup hàng tận nhà cho những kiện hàng từ 10kg trở lên. 
                  Nhập trọng lượng (kg) và Kích thước (dài x rộng x cao) theo cm vào đây. 
                  Ví dụ: 
                  - Kiện 1: 25kg, 30 x 40 x 46cm 
                  - Kiện 2: 6kg, 20 x  30 x 35cm`}
                    rows={5}
                    rowsMax={5}
                    multiline
                    value={pickupContent}
                    onChange={(e) => setPickupContent(e.target.value)}
                  />
                </>
              )}
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  checked={sendSydney}
                  onChange={() => {
                    setSendSydney(!sendSydney);
                    if (!sendSydney) setPickup(false);
                  }}
                />
                <Typography variant="body1" color="default">
                  Dành cho khách gửi hàng về kho Sydney
                </Typography>
              </Box>
              {sendSydney && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="h6" color="default">
                      Số tracking number
                    </Typography>
                    <MyInput
                      id="ghi chu"
                      placeholder=""
                      value={trackingNumber}
                      onChange={(e) => setTrackingNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="h6" color="default">
                      Mã đơn hàng
                    </Typography>
                    <MyInput
                      id="ghi chu"
                      placeholder=""
                      value={orderCode}
                      onChange={(e) => setOrderCode(e.target.value)}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box mt={2} textAlign="right">
          <MyButton
            text="Lưu"
            onClick={handleValid}
            color="success"
            style={{ marginRight: 15 }}
          />
          <NavLink to="/vcms/order/list">
            <MyButton text="Hủy" color="secondary" />
          </NavLink>
        </Box>
      </Box>
      {/* Dialog */}
      <Dialog fullWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Nontification</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              <b>Thông tin người nhận</b>
            </Typography>
            {provinceSelected === provinceDefault && (
              <Typography>+ Province invalid</Typography>
            )}
            {districtSelected === districtDefault && (
              <Typography>+ District invalid</Typography>
            )}
            {wardSelected === wardDefault && (
              <Typography>+ Ward invalid</Typography>
            )}
            <br />
            <Typography>
              <b>Sản phẩm</b>
            </Typography>
            {categoryDescription === categoryDescriptionDefault && (
              <Typography>+ Category invalid</Typography>
            )}
            {productArray.map((item, index) => {
              return (
                <>
                  {item.category === categoryDescriptionDefault && (
                    <Typography>+ Category invalid</Typography>
                  )}
                  {item.nameProduct === "" && (
                    <Typography>+ Product name invalid</Typography>
                  )}
                  {item.totalWeight <= 0 && (
                    <Typography>+ Product weight invalid</Typography>
                  )}
                  {item.totalLength <= 0 && (
                    <Typography>+ Product lenght invalid</Typography>
                  )}
                  {item.totalPrice <= 0 && (
                    <Typography>+ Product price invalid</Typography>
                  )}
                </>
              );
            })}
            <br />
            <Typography>
              <b>Thông tin thanh toán</b>
            </Typography>
            {height <= 0 && <Typography>+ Height invalid</Typography>}
            {weight <= 0 && <Typography>+ Weight invalid</Typography>}
            {width <= 0 && <Typography>+ Width invalid</Typography>}
            {length <= 0 && <Typography>+ Length invalid</Typography>}
            {paymentTypeSeclect === paymentTypeDefault && (
              <Typography>+ Payment Type invalid</Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog */}
      <Dialog fullWidth="md" open={openValid} onClose={handleCloseValid}>
        <DialogTitle>Nontification</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              <b>Trạng thái đơn hàng</b>
            </Typography>
            {orderStatusSelected.id === "18"
              ? !codeGHN && <Typography>+ Code GHN invalid</Typography>
              : null}
            <br />
            <Typography>
              <b>Thông tin người gửi</b>
            </Typography>
            {!nameSend && <Typography>+ Name Send invalid</Typography>}
            {!phoneSend && <Typography>+ Phone Send invalid</Typography>}
            {!emailSend && <Typography>+ Email Send invalid</Typography>}
            {!addressSend && <Typography>+ Address Send invalid</Typography>}
            <br />
            <Typography>
              <b>Thông tin người nhận</b>
            </Typography>
            {!nameTo && <Typography>+ Name To invalid</Typography>}
            {!phoneTo && <Typography>+ Phone To invalid</Typography>}
            {!emailTo && <Typography>+ Email To invalid</Typography>}
            {!addressTo && <Typography>+ Address To invalid</Typography>}
            {provinceSelected === provinceDefault && (
              <Typography>+ Province invalid</Typography>
            )}
            {districtSelected === districtDefault && (
              <Typography>+ District invalid</Typography>
            )}
            {wardSelected === wardDefault && (
              <Typography>+ Ward invalid</Typography>
            )}
            <br />
            <Typography>
              <b>Sản phẩm</b>
            </Typography>
            {categoryDescription === categoryDescriptionDefault && (
              <Typography>+ Category invalid</Typography>
            )}
            {productArray.map((item, index) => {
              return (
                <>
                  {item.category === categoryDescriptionDefault && (
                    <Typography>+ Category invalid</Typography>
                  )}
                  {item.nameProduct === "" && (
                    <Typography>+ Product name invalid</Typography>
                  )}
                  {item.totalWeight <= 0 && (
                    <Typography>+ Product weight invalid</Typography>
                  )}
                  {item.totalLength <= 0 && (
                    <Typography>+ Product lenght invalid</Typography>
                  )}
                  {item.totalPrice <= 0 && (
                    <Typography>+ Product price invalid</Typography>
                  )}
                </>
              );
            })}
            <br />
            <Typography>
              <b>Thông tin thanh toán</b>
            </Typography>
            {height <= 0 && <Typography>+ Height invalid</Typography>}
            {weight <= 0 && <Typography>+ Weight invalid</Typography>}
            {width <= 0 && <Typography>+ Width invalid</Typography>}
            {length <= 0 && <Typography>+ Length invalid</Typography>}
            {standardFee === 0 && (
              <Typography>+ Fee Standard invalid</Typography>
            )}
            {expressFee === 0 && <Typography>+ Fee Express invalid</Typography>}
            {totalCount === 0 && <Typography>+ Bales invalid</Typography>}
            <br />
            <Typography>
              <b>Thông tin khác</b>
            </Typography>
            {sendSydney
              ? !trackingNumber && (
                  <Typography>+ Tracking Number invalid</Typography>
                )
              : null}
            {sendSydney
              ? !orderCode && <Typography>+ Order Code invalid</Typography>
              : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValid} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog */}
      <Dialog fullWidth="md" open={openError} onClose={handleCloseError}>
        <DialogTitle>Nontification</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>Update Order Fail</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog */}
      <Dialog fullWidth="md" open={openSuccess} onClose={handleCloseSuccess}>
        <DialogTitle>Nontification</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>Update Order Success</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccess} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
