export const orderList = [
  {
    "id": 19,
    "code": "KOMPXMAOID",
    "price": 100,
    "bales": 8,
    "weight": 3.2,
    "number_customer": null,
    "pickup": 0,
    "fee": 42.66,
    "currency": "AUD",
    "warehouse_sydney": 0,
    "deleted_at": null,
    "send": {
      "order_id": 19,
      "name": "Ngụy Tú Quỳnh",
      "phone": "0406013702",
      "email": "snowwright2000@gmail.com",
      "country": "au",
      "address": "105 homebush road, Strathfield, NSW 2135",
      "created_at": "2021-03-01 09:49:25",
      "updated_at": "2021-03-10T09:28:32.000000Z"
    },
    "recipient": {
      "order_id": 19,
      "name": "Võ Thị Mai Thy",
      "phone": "0918362736",
      "email": "maithuy6777@gmail.com",
      "province_id": 2,
      "district_id": 43,
      "ward_id": 751,
      "address": "1D/12 Bình Gĩa",
      "created_at": "2021-03-01 09:49:25",
      "updated_at": "2021-03-10T09:28:32.000000Z"
    }
  },
  {
    "id": 10,
    "code": "LZMR709D4B",
    "price": 433,
    "bales": 2,
    "weight": 6,
    "number_customer": null,
    "pickup": 0,
    "fee": 1334998,
    "currency": "VND",
    "warehouse_sydney": 0,
    "deleted_at": null,
    "send": {
      "order_id": 10,
      "name": "Trần Diệu Anh",
      "phone": "+610401324045",
      "email": "dieuanhtran2501@gmail.com",
      "country": "au",
      "address": "Unit 117B, 142 Abercrombie st, Redfern, NSW 2016",
      "created_at": "2021-02-03 00:44:47",
      "updated_at": "2021-02-26T09:54:29.000000Z"
    },
    "recipient": {
      "order_id": 10,
      "name": "Trần Tuấn Dũng",
      "phone": "+84 903 929 325",
      "email": "dungtrantuan02@gmail.com",
      "province_id": 2,
      "district_id": 32,
      "ward_id": 599,
      "address": "280/A40 Lương Định Của, phường An Phú, Quận 2, TP.HCM",
      "created_at": "2021-02-03 00:44:47",
      "updated_at": "2021-02-26T09:54:29.000000Z"
    }
  },
  {
    "id": 8,
    "code": "WERAUYEQMJ",
    "price": 20,
    "bales": 1,
    "weight": 0.2,
    "number_customer": null,
    "pickup": 0,
    "fee": 3.58,
    "currency": "AUD",
    "warehouse_sydney": 0,
    "deleted_at": null,
    "send": {
      "order_id": 8,
      "name": "Nguyen Hoang Thao",
      "phone": "+61481785968",
      "email": "hoangthao.060200@gmail.com",
      "country": "au",
      "address": "8A Ryrie Rd, Earlwood NSW, 2206",
      "created_at": "2021-01-27 08:42:21",
      "updated_at": "2021-02-04T05:38:10.000000Z"
    },
    "recipient": {
      "order_id": 8,
      "name": "Nguyễn Trung Hiếu",
      "phone": "0909363615",
      "email": "Nguyentrunghieu06122000@gmail.com",
      "province_id": 2,
      "district_id": 50,
      "ward_id": 866,
      "address": "23/1 Huỳnh Mẫn Đạt",
      "created_at": "2021-01-27 08:42:21",
      "updated_at": "2021-02-04T05:38:10.000000Z"
    }
  },
  {
    "id": 7,
    "code": "PUYZJLBGTX",
    "price": 13,
    "bales": 1,
    "weight": 7,
    "number_customer": null,
    "pickup": 1,
    "fee": 93.56,
    "currency": "AUD",
    "warehouse_sydney": 0,
    "deleted_at": null,
    "send": {
      "order_id": 7,
      "name": "DaoTran",
      "phone": "+61450247992",
      "email": "Daotran2407@gmail.com",
      "country": "au",
      "address": "507/2 Saunders close, Macquarie park, nsw 2113",
      "created_at": "2021-01-24 02:09:57",
      "updated_at": "2021-02-07T04:41:52.000000Z"
    },
    "recipient": {
      "order_id": 7,
      "name": "Trần hữu hiệp",
      "phone": "0918500636",
      "email": "Daotransydney@gmail.com",
      "province_id": 2,
      "district_id": 44,
      "ward_id": 763,
      "address": "92 Trần Tấn",
      "created_at": "2021-01-24 02:09:57",
      "updated_at": "2021-02-07T04:41:52.000000Z"
    }
  },
  {
    "id": 6,
    "code": "GGFPORIOD0",
    "price": 143,
    "bales": 3,
    "weight": 0.3,
    "number_customer": null,
    "pickup": 0,
    "fee": 4.88,
    "currency": "AUD",
    "warehouse_sydney": 0,
    "deleted_at": null,
    "send": {
      "order_id": 6,
      "name": "Nguyen Hoang Thao",
      "phone": "+61481785968",
      "email": "hoangthao.060200@gmail.com",
      "country": "au",
      "address": "8A Ryrie Rd, Earlwood NSW, 2206",
      "created_at": "2021-01-22 21:38:33",
      "updated_at": "2021-01-28T13:42:51.000000Z"
    },
    "recipient": {
      "order_id": 6,
      "name": "Pham Nguyen Bao Ngan",
      "phone": "0946123879",
      "email": "pnbngan29@gmail.com",
      "province_id": 2,
      "district_id": 40,
      "ward_id": 718,
      "address": "279 Nguyễn Tri Phương",
      "created_at": "2021-01-22 21:38:33",
      "updated_at": "2021-01-28T13:42:51.000000Z"
    }
  },
  {
    "id": 4,
    "code": "PQKM9UUAVZ",
    "price": 4,
    "bales": 2,
    "weight": 8.25,
    "number_customer": null,
    "pickup": 0,
    "fee": 113.88,
    "currency": "AUD",
    "warehouse_sydney": 0,
    "deleted_at": null,
    "send": {
      "order_id": 4,
      "name": "Thi Thao Anh Nguyen",
      "phone": "+61431244796",
      "email": "thithaoanh.nguyen@students.mq.edu.au",
      "country": "au",
      "address": "29 Parklands Road, North Ryde, NSW 2113",
      "created_at": "2020-12-23 10:56:04",
      "updated_at": "2021-01-18T10:25:33.000000Z"
    },
    "recipient": {
      "order_id": 4,
      "name": "Thi Nguyet Dong",
      "phone": "+84 776451875",
      "email": "anthang2007@gmail.com",
      "province_id": 23,
      "district_id": 279,
      "ward_id": 3792,
      "address": "Cửa hàng Toàn Thắng ( Công ty TNHH Thương mại An Thắng)",
      "created_at": "2020-12-23 10:56:04",
      "updated_at": "2021-01-18T10:25:33.000000Z"
    }
  },
  {
    "id": 3,
    "code": "DD5Y1MRH0A",
    "price": 67,
    "bales": 14,
    "weight": 67,
    "number_customer": null,
    "pickup": 0,
    "fee": 890.53,
    "currency": "AUD",
    "warehouse_sydney": 0,
    "deleted_at": null,
    "send": {
      "order_id": 3,
      "name": "Nguyen Ngoc",
      "phone": "+61434373660",
      "email": "huyen.nguyen4241@gmail.com",
      "country": "au",
      "address": "61",
      "created_at": "2020-12-23 07:40:16",
      "updated_at": "2020-12-23T07:40:16.000000Z"
    },
    "recipient": {
      "order_id": 3,
      "name": "Hu",
      "phone": "Hu",
      "email": "Hu@gmail.com",
      "province_id": 62,
      "district_id": 706,
      "ward_id": 11311,
      "address": "26",
      "created_at": "2020-12-23 07:40:16",
      "updated_at": "2020-12-23T07:40:16.000000Z"
    }
  },
  {
    "id": 1,
    "code": "7JW8K7R0ZH",
    "price": 10000,
    "bales": 1,
    "weight": 1,
    "number_customer": null,
    "pickup": 0,
    "fee": 12.97,
    "currency": "AUD",
    "warehouse_sydney": 0,
    "deleted_at": null,
    "send": {
      "order_id": 1,
      "name": "tien phat",
      "phone": "+61484849495",
      "email": "tienphat.nguyen@outlook.com",
      "country": "au",
      "address": "3 carlton",
      "created_at": "2020-12-22 08:41:23",
      "updated_at": "2020-12-22T08:45:54.000000Z"
    },
    "recipient": {
      "order_id": 1,
      "name": "tesst",
      "phone": "0376956709",
      "email": "phat.nguyen@epis.vn",
      "province_id": 2,
      "district_id": 43,
      "ward_id": 754,
      "address": "935 lac long quan",
      "created_at": "2020-12-22 08:41:23",
      "updated_at": "2020-12-22T08:45:54.000000Z"
    }
  }
].map((item) => ({ 
  Index: item.id, 
  ["Mã tờ khai"]: item.code,/** eslint-disable no-useless-computed-key */
  ["Họ tên người gửi"]: item.send.name,
  ["Số điện thoại người gửi"]: item.send.phone,
  ["Địa chỉ người gửi"]: item.send.address,
  ["Họ tên người nhận"]: item.recipient.name,
  ["Số điện thoại người nhận"]: item.recipient.phone,
  ["Địa chỉ người nhận"]: item.recipient.address,
  ["Phí vận chuyển (tạm tính)"]: `${item.fee} (${item.currency})`
}));

export const languageList = [
  {
    "id": 4,
    "code": "vi",
    "name": "Tiếng Việt",
    "priority": 1,
    "flag_icon": "https://dashboard.epispost.com//cms/images/assets/no-img_50x50.png",
    "vi_name": null,
    "created_at": null,
    "updated_at": "2020-07-21T13:22:43.000000Z",
    "deleted_at": null
  }
].map((item) => ({
  id: item.id,
  value: item.code,
  label: item.name
}));

export const statusList = [
  {
    id: '1',
    value: 'active',
    label: 'Active'
  },
  {
    id: '2',
    value: 'de_active',
    label: 'DeActive'
  }
]

export const currencyList = [{ id: 1, value: "VND", label: "Việt Nam Đồng" }, { id: 1, value: "AUD", label: "Đô la Úc" }]

export const shipServiceList = [
  {
    id: 1,
    value: "2",
    label: "Standard"
  },
  {
    id: 2,
    value: "1",
    label: "Express"
  }
]
