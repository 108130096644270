import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { forgotPassword } from "src/state/auth/reducer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorForgotPassword, setErrorForgotPassword] = useState("");
  const [statusForgotPassword, setStatusForgotPassword] = useState("");

  const getCodeFromEmail = (values, callback) => {
    if (statusForgotPassword === "success") {
      navigate("/login", { replace: true });
    } else {
      dispatch(
        forgotPassword({
          payload: {
            email: values?.email,
          },
          callback: (res) => {
            if (res?.errors?.length) {
              callback.setSubmitting(false);
              setErrorForgotPassword(res?.errors[0].message);
            }
            if (res?.data?.forgotPassword?.status === "success") {
              callback.setSubmitting(false);
              setStatusForgotPassword(res?.data?.forgotPassword?.status);
            }
          },
        })
      );
    }
  };

  return (
    <Page className={classes.root} title="Forgot Password">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "", //admin2021@yopmail.com
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={getCodeFromEmail}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={1}>
                  <Typography color="textPrimary" variant="h2">
                    Forgot Password
                  </Typography>
                </Box>

                {statusForgotPassword !== "success" && (
                  <>
                    <Box mb={1}>
                      <Typography color="textSecondary" variant="body1">
                        Enter your email address to reset your password
                      </Typography>
                    </Box>

                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </>
                )}

                <Typography style={{ color: "red" }}>
                  {errorForgotPassword}
                </Typography>

                {statusForgotPassword === "success" && (
                  <Box mt={3} mb={4}>
                    <Typography>
                      We sent a reset password email to {values?.email}.
                    </Typography>
                    <Typography>
                      Please click the reset password link to set your new
                      password.
                    </Typography>
                  </Box>
                )}

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {statusForgotPassword === "success" ? "Back" : "Send"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPassword;
