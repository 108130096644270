import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import font from "../../assets/font/OpenSans-Bold.ttf";

Font.register({
  family: "Open-Sans",
  src: font,
  fontWeight: "300",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
    fontFamily: "Open-Sans",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    fontSize: 10,
    width: 550,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 20,
  },
  cell: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch",
    minWidth: "25%",
    minHeight: 20,
    color: "#554444",
  },
  text: {
    flexGrow: 1,
    flexShrink: 1,
    color: "#554444",
    flexWrap: "wrap",
    flexBasis: "auto",
    flexDirection: "column",
  },
});

export default function MyDocument({ dataOrder }) {
  const freight =
    (dataOrder?.weight * dataOrder?.config_fee_ship).toFixed(2) || 0;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={{ margin: 30 }}>
          <View style={styles.table}>
            <View style={[styles.row]}>
              <View style={[styles.cell]}>
                <Image
                  src="/static/images/epispost_logo_200x200.png"
                  style={{ width: 100 }}
                />
              </View>

              <Text style={[styles.cell, { color: "#000" }]}>
                EPIS Post - Giao Vận An Toàn
              </Text>
            </View>

            <View style={[styles.row]}>
              <Text style={[styles.cell]}></Text>
              <Text style={[styles.cell]}>3 Carlton St, Chippendale,</Text>
            </View>

            <View style={[styles.row]}>
              <Text style={[styles.cell]}></Text>
              <Text style={[styles.cell]}> NSW 2008, Australia </Text>
            </View>
          </View>

          <View style={{ marginBottom: 20 }}>
            <Text
              style={{
                fontSize: 23,
                fontWeight: "bold",
              }}
            >
              HOÁ ĐƠN
            </Text>
          </View>

          <View
            style={{
              fontSize: 9,
              flexDirection: "row",
            }}
          >
            <View style={[styles.text, { width: "25%", marginRight: 5 }]}>
              <Text
                style={{ marginBottom: 5, color: "#000", fontWeight: "bold" }}
              >
                Người gửi:
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.order_send?.name}
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.order_send?.email}
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.order_send?.phone}
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.order_send?.address}
              </Text>
            </View>

            <View style={[styles.text, { width: "25%", marginRight: 5 }]}>
              <Text
                style={{ marginBottom: 5, color: "#000", fontWeight: "bold" }}
              >
                Vận chuyển đến:
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.order_recipient?.name}
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.order_recipient?.email}
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.order_recipient?.phone}
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.order_recipient?.address}
              </Text>
            </View>

            <View style={[styles.text, { width: "20%", marginRight: 5 }]}>
              <Text
                style={{ marginBottom: 5, color: "#000", fontWeight: "bold" }}
              >
                Ngày lập hoá đơn:
              </Text>
              <Text
                style={{ marginBottom: 5, color: "#000", fontWeight: "bold" }}
              >
                Mã tờ khai:
              </Text>
              <Text
                style={{ marginBottom: 5, color: "#000", fontWeight: "bold" }}
              >
                Ngày đặt hàng:
              </Text>
              <Text
                style={{ marginBottom: 5, color: "#000", fontWeight: "bold" }}
              >
                Phương thức thanh toán:
              </Text>
            </View>

            <View style={[styles.text, { width: "20%" }]}>
              <Text style={{ marginBottom: 5 }}>
                {new Date().toLocaleDateString()}
              </Text>
              <Text style={{ marginBottom: 5 }}>{dataOrder?.code}</Text>
              <Text style={{ marginBottom: 5 }}>
                {new Date(dataOrder?.created_at).toLocaleDateString()}
              </Text>
              <Text style={{ marginBottom: 5 }}>
                {dataOrder?.payment_type?.name}
              </Text>
            </View>
          </View>

          <View style={[styles.table, { marginTop: 20 }]}>
            <View
              style={[
                styles.row,
                { backgroundColor: "#000", color: "#fff", padding: 5 },
              ]}
            >
              <Text style={[styles.cell, { width: "50%", color: "#fff" }]}>
                Nội dung
              </Text>
              <Text style={[styles.cell, { color: "#fff" }]}>Số kiện</Text>
              <Text style={[styles.cell, { color: "#fff" }]}>
                Khối lượng(kg)
              </Text>
            </View>

            <View style={[styles.row]}>
              <Text style={[styles.cell, { width: "50%" }]}>
                Vận chuyển từ Autrastilia về Việt Nam
              </Text>
              <Text style={[styles.cell]}>{dataOrder?.bales}</Text>
              <Text style={[styles.cell]}>{dataOrder?.weight}</Text>
            </View>

            <View style={[styles.row]}>
              <Text style={[styles.cell, { width: "50%" }]}></Text>
              <Text style={[styles.cell, { color: "#000" }]}>Đơn giá/kg</Text>
              <Text style={[styles.cell]}>
                {dataOrder?.config_fee_ship.toFixed(2)}$
              </Text>
            </View>

            <View style={[styles.row]}>
              <Text style={[styles.cell, { width: "50%" }]}></Text>
              <Text style={[styles.cell, { color: "#000" }]}>
                Cước vận chuyển
              </Text>
              <Text style={[styles.cell]}>{freight}$</Text>
            </View>

            <View style={[styles.row]}>
              <Text style={[styles.cell, { width: "50%" }]}></Text>
              <Text style={[styles.cell, { color: "#000" }]}>
                Vận chuyển nội địa
              </Text>
              <Text style={[styles.cell]}>
                {(dataOrder?.fee - freight).toFixed(2)}$
              </Text>
            </View>

            <View style={[styles.row]}>
              <Text style={[styles.cell, { width: "50%" }]}></Text>
              <Text
                style={[
                  styles.cell,
                  {
                    borderTop: "1px solid black",
                    paddingTop: 10,
                    color: "#000",
                  },
                ]}
              >
                Tổng cộng
              </Text>
              <Text
                style={[
                  styles.cell,
                  { borderTop: "1px solid black", paddingTop: 10 },
                ]}
              >
                {dataOrder?.fee.toFixed(2)}$
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            left: "10%",
            width: "80%",
            bottom: "10%",
            position: "absolute",
          }}
        >
          <Text
            style={{
              fontSize: 10,
              paddingTop: 5,
              textAlign: "center",
              borderTop: "1px solid #8d8888",
            }}
          >
            Bản quyền thuộc Công Ty TNHH EPIS Việt Nam và EPIS Post Australia
          </Text>
        </View>
      </Page>
    </Document>
  );
}
