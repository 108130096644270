import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';

import { authSelector } from 'src/state/auth/reducer';
import { setDevice } from 'src/state/app/reducer';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      padding: 20
    },
    [theme.breakpoints.down('sm')]: {
      padding: 15
    }
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch();
  
  const handleResize = () => {
    if (window.innerWidth <= 600) dispatch(setDevice(1));
    else if (window.innerWidth <= 960) dispatch(setDevice(2));
    else dispatch(setDevice(3));
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  /* eslint-disable no-return-assign */
  
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Box m={2}>
              <Outlet />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
