import _ from "lodash";
import { takeEvery, put, all, call } from "redux-saga/effects";
import {
  getList,
  getSuccess,
  getError,
  deleteOrder,
  deleteOrderSuccess,
} from "./reducer";
import callGraphqlApi from "src/utils/callGraphqlApi";
import { getOrderList, deleteOrderQuery } from "./query";

function* watchGetList({ payload }) {
  const resGetList = yield call(
    callGraphqlApi,
    "getList",
    getOrderList,
    payload
  );
  const errors = _.get(resGetList, "errors", null);
  if (errors) {
    return yield put(getError(_.get(errors, "[0].message", null)));
  }
  const data = _.get(resGetList, "data", null);
  if (data) {
    yield put(getSuccess(_.get(data, "getOrderList", [])));
  }
}

function* watchDeleteOrder({ payload: { payload, callback } }) {
  const resDelete = yield call(
    callGraphqlApi,
    "deleteOrder",
    deleteOrderQuery,
    payload
  )
  const error = _.get(resDelete, "errors[0].message", null);
  if (error) {
    if (callback) callback({error})
  }
  const data =  _.get(resDelete, "data.deleteOrder", null);
  if (data) {
    console.log(data)
    yield put(deleteOrderSuccess(data))
    if (callback) callback({data})
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(getList.type, watchGetList),
    takeEvery(deleteOrder.type, watchDeleteOrder),
  ]);
};
