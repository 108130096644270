import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { roleListSelector, getList } from "src/state/role-list/reducer";
import { appSelector } from "src/state/app/reducer";
import { Box, Typography } from "@material-ui/core";
import PageTitle from "src/components/PageTitle";
import MyTable from "src/components/Table";
import MyInput from "src/components/Input";
import MySelect from "src/components/Select";
import MyButton from "src/components/Button";

import { statusList } from "src/utils/constant";

const limit = 20;

export default function RoleList() {
  const dispatch = useDispatch();

  const { device } = useSelector(appSelector);

  // Lay data
  const { loading, error, data, count } = useSelector(roleListSelector);

  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(statusList[0]);

  useEffect(() => {
    handleSearch();
  }, []);

  const handleDelete = (item) => {};

  const handleSearch = () => {
    setPage(1);
    dispatch(
      getList({
        limit,
        offset: 0,
        keyword,
        // status: status.value,
      })
    );
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(
      getList({
        limit,
        offset: (newPage - 1) * limit,
        keyword,
        // status: status.value,
      })
    );
  };

  const handleChangeStatus = (e) => {
    const selected = statusList.find((item) => item.value === e.target.value);
    setStatus(selected);
  };

  return (
    <>
      <PageTitle>Management Role</PageTitle>
      <Box p={2} mt={2} style={{ background: "white" }} borderRadius={2}>
        <Box mb={2}>
          <MyInput
            placeholder="Keyword"
            variant="outlined"
            width={device === 3 ? "unset" : null}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <MySelect
            label="Select status"
            options={statusList}
            variant="outlined"
            width={device === 3 ? "unset" : null}
            value={status.value}
            onChange={handleChangeStatus}
          />
          <MyButton text="Search" color="primary" onClick={handleSearch} />
          <NavLink to="/vcms/role/add">
            <MyButton text="Add" color="success" />
          </NavLink>
        </Box>
        {loading ? (
          <Typography variant="h4">Loading...</Typography>
        ) : error ? (
          <Typography variant="h4" color="secondary">
            {error}
          </Typography>
        ) : !data || data.length === 0 ? (
          <Typography variant="h4">No results</Typography>
        ) : (
          <MyTable
            data={data.map((item, index) => ({
              Index: item.id,
              DisplayName: item.display_name,
              Description: item.description,
            }))}
            category="role"
            page={page}
            onChange={handleChangePage}
            onDelete={handleDelete}
            count={
              count % limit === 0 ? count / limit : parseInt(count / limit) + 1
            }
          />
        )}
      </Box>
    </>
  );
}
