export const getUserRedux = `
  query getUser ($id: ID!) {
    getUser (id: $id) {
      id
      username
      email
      full_name
      country
      address
      image
      type
      role_user {
        role {
          id
          name
          display_name
          description
        }
      }
      user_phone {
        id
        nation_phone_code
        phone
        code
      }
    }
  }
`;
