import getLD from "lodash.get";
import { takeEvery, put, all, call } from "redux-saga/effects";
import {
  getOrderReducer,
  getSuccess,
  getError,
  getFee,
  createOrder,
  updateOrder
} from "./reducer";
import callGraphqlApi from "src/utils/callGraphqlApi";
import { getFeeQuery, getOrder, createOrderQuery, updateOrderQuery } from "./query";

function* watchGet({ payload: { payload, callback } }) {
  const resGet = yield call(
    callGraphqlApi,
    "getOrderReducer",
    getOrder,
    { id: payload }
  );
  const errors = getLD(resGet, "errors", null);
  if (errors) {
    const error = getLD(errors, "[0].message", null)
    if (callback) callback({error})
    return yield put(getError(error));
  }
  
  const data = _.get(resGet, "data", null);
  if (data) {
    const dataOrder = getLD(data, "getOrder", null)
    if (callback) callback({data: dataOrder})
    return yield put(getSuccess(dataOrder));
  }
}

function* watchGetFee({ payload: { payload, callback } }) {
  const resGet = yield call(
    callGraphqlApi,
    "getFeeQuery",
    getFeeQuery,
    payload
  );
  const errors = getLD(resGet, "errors", null);
  if (callback) {
    if (errors) {
      return callback(getError(getLD(errors, "[0].message", null)));
    }
    const data = getLD(resGet, "data", null);
    if (data) {
      callback(getSuccess(getLD(data, "getFee", null)));
    }
  }
}

// payload : field data
function* watchCreateOrder({ payload: { payload, callback } }) {
  const resGet = yield call(
    callGraphqlApi,
    "createOrder",
    createOrderQuery,
    payload
  );
  const errors = getLD(resGet, "errors", null);

  if (callback) {
    if (errors) {
      return callback({error: getLD(errors, "[0].message", null)});
    }
    const data = getLD(resGet, "data", null);
    if (data) {
      return callback({data: getLD(data, "createOrder", null)});
    }
  }
}

function* watchUpdateOrder({ payload: { payload, callback } }) {
  const resGet = yield call(
    callGraphqlApi,
    "updateOrder",
    updateOrderQuery,
    payload
  );
  const errors = getLD(resGet, "errors", null);

  if (callback) {
    if (errors) {
      return callback({error: getLD(errors, "[0].message", null)});
    }
    const data = getLD(resGet, "data", null);
    if (data) {
      return callback({data: getLD(data, "updateOrder", null)});
    }
  }
}
// contant .type
export const rootSagas = function* rootSagas() {
  yield all([
    takeEvery(getFee.type, watchGetFee),
    takeEvery(createOrder.type, watchCreateOrder),
    takeEvery(updateOrder.type, watchUpdateOrder),
    takeEvery(getOrderReducer.type, watchGet),
  ]);
};
