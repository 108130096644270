import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
// import RegisterView from "src/views/auth/RegisterView";
import CategoryList from "src/views/category/CategoryList";
import CategoryAdd from "src/views/category/CategoryAdd";
import OrderStatusList from "src/views/order-status/OrderStatusList";
import OrderStatusAdd from "src/views/order-status/OrderStatusAdd";
import CarrierOrderStatusList from "src/views/carrier-order-status/CarrierOrderStatusList";
import CarrierOrderStatusAdd from "src/views/carrier-order-status/CarrierOrderStatusAdd";
import OrderList from "src/views/order/OrderList";
import OrderAdd from "src/views/order/OrderAdd";
import PaymentTypeList from "src/views/payment-type/PaymentTypeList";
import PaymentTypeAdd from "src/views/payment-type/PaymentTypeAdd";
import CarrierList from "src/views/carrier/CarrierList";
import CarrierAdd from "src/views/carrier/CarrierAdd";
import ProvinceList from "src/views/province/ProvinceList";
import ProvinceAdd from "src/views/province/ProvinceAdd";
import DistrictList from "src/views/district/DistrictList";
import DistrictAdd from "src/views/district/DistrictAdd";
import WardList from "src/views/ward/WardList";
import WardAdd from "src/views/ward/WardAdd";
import SettingDetail from "src/views/setting/SettingDetail";
import CategoryEdit from "./views/category/CategoryEdit";
import OrderStatusEdit from "./views/order-status/OrderStatusEdit";
import OrderEdit from "./views/order/OrderEdit";
import PaymentTypeEdit from "./views/payment-type/PaymentTypeEdit";
import UserList from "./views/user/UserList";
import RoleList from "./views/role/RoleList";
import RoleAdd from "./views/role/RoleAdd";
import UserAdd from "./views/user/UserAdd";
import UserEdit from "./views/user/UserEdit";
import ForgotPassword from "src/views/auth/ForgotPassword";
import ResetPassword from "./views/auth/ResetPassword";
import OrderBilling from "./views/order-billing";
import VerificationAccount from "./views/verifyAccount";
import RoleEdit from "./views/role/RoleEdit";

const routes = (isAuth) => [
  {
    path: "vcms",
    element: isAuth ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: "category/list", element: <CategoryList /> },
      { path: "category/add", element: <CategoryAdd /> },
      { path: "category/edit/:id", element: <CategoryEdit /> },
      { path: "order-status/list", element: <OrderStatusList /> },
      { path: "order-status/add", element: <OrderStatusAdd /> },
      { path: "order-status/edit/:id", element: <OrderStatusEdit /> },
      {
        path: "carrier-order-status/list",
        element: <CarrierOrderStatusList />,
      },
      { path: "carrier-order-status/add", element: <CarrierOrderStatusAdd /> },
      { path: "order/list", element: <OrderList /> },
      { path: "order/add", element: <OrderAdd /> },
      { path: "order/edit/:id", element: <OrderEdit /> },
      { path: "payment-type/list", element: <PaymentTypeList /> },
      { path: "payment-type/add", element: <PaymentTypeAdd /> },
      { path: "payment-type/edit/:id", element: <PaymentTypeEdit /> },
      { path: "carrier/list", element: <CarrierList /> },
      { path: "carrier/add", element: <CarrierAdd /> },
      { path: "province/list", element: <ProvinceList /> },
      { path: "province/add", element: <ProvinceAdd /> },
      { path: "district/list", element: <DistrictList /> },
      { path: "district/add", element: <DistrictAdd /> },
      { path: "ward/list", element: <WardList /> },
      { path: "ward/add", element: <WardAdd /> },
      { path: "role/list", element: <RoleList /> },
      { path: "role/add", element: <RoleAdd /> },
      { path: "role/edit/:id", element: <RoleEdit /> },
      { path: "user/list", element: <UserList /> },
      { path: "user/add", element: <UserAdd /> },
      { path: "user/edit/:id", element: <UserEdit /> },
      { path: "setting/detail", element: <SettingDetail /> },
      { path: "/order-billing/:id", element: <OrderBilling /> },
      { path: "verifyAccount", element: <VerificationAccount /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "/", element: <Navigate to="/vcms/order/list" /> },
    ],
  },
  {
    path: "/",
    element: !isAuth ? <MainLayout /> : <Navigate to="/vcms/order/list" />,
    children: [
      { path: "login", element: <LoginView /> },
      // { path: "register", element: <RegisterView /> },
      { path: "forgotPassword", element: <ForgotPassword /> },
      { path: "resetPassword", element: <ResetPassword /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
