import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { appSelector } from 'src/state/app/reducer';
import { carrierOrderStatusListSelector, getList } from 'src/state/carrier-order-status-list/reducer';
import {
  Box, Typography,
} from '@material-ui/core';

import PageTitle from 'src/components/PageTitle';
import MyTable from 'src/components/Table';
import MyInput from 'src/components/Input';
import MySelect from 'src/components/Select';
import MyButton from 'src/components/Button';

import { languageList } from './data';
import { statusList } from 'src/utils/constant';


const limit = 20;

export default function CarrierOrderStatusList() {
  const dispatch = useDispatch();
  const { loading, error, data, count } = useSelector(carrierOrderStatusListSelector);
  const { device } = useSelector(appSelector);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [language, setLanguage] = useState(languageList[0]);
  const [status, setStatus] = useState(statusList[0]);

  useEffect(() => {
    handleSearch();
  }, []);

  const handleDelete = (item) => {
  }

  const handleSearch = () => {
    dispatch(getList({
      limit,
      offset: 0,
      keyword,
      language_code: language.value,
      status: status.value
    }));
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(getList({
      limit,
      offset: (newPage - 1) * limit,
      keyword,
      language_code: language.value,
      status: status.value
    }));
  }

  const handleChangeLanguage = (e) => {
    const selected = languageList.find(item => item.value === e.target.value)
    setLanguage(selected)
  }

  const handleChangeStatus = (e) => {
    const selected = statusList.find(item => item.value === e.target.value)
    setStatus(selected)
  }

  return (
    <>
      <PageTitle>Carrier Order Status List</PageTitle>
      <Box p={2} mt={2} style={{ background: 'white' }} borderRadius={2}>
        <Box mb={device <= 2 ? 0 : 2}>
          <MyInput
            placeholder="Keyword"
            variant="outlined"
            width={device === 3 ? 'unset' : null}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <MySelect
            label="Chọn ngôn ngữ"
            options={languageList}
            variant="outlined"
            width={device === 3 ? 'unset' : null}
            value={language.value}
            onChange={handleChangeLanguage}
          />
          <MySelect
            label="Select status"
            options={statusList}
            variant="outlined"
            width={device === 3 ? 'unset' : null}
            value={status.value}
            onChange={handleChangeStatus}
          />
          <MyButton text="Search" color="primary" onClick={handleSearch} />
          <NavLink to="/vcms/carrier-order-status/add">
            <MyButton text="Add" color="success" />
          </NavLink>
        </Box>
        {loading ? (
          <Typography variant="h4">Loading...</Typography>
        ) : error ? (
          <Typography variant="h4" color="secondary">{error}</Typography>
        ) : (!data || data.length === 0) ? (
          <Typography variant="h4">No results</Typography>
        ) : (
                <MyTable
                  data={data.map((item) => ({
                    Index: item.id,
                    Title: item.name
                  }))}
                  page={page}
                  onChange={handleChangePage}
                  onDelete={handleDelete}
                  count={count % limit === 0 ? count / limit : parseInt(count / limit) + 1}
                />
              )}
      </Box>
    </>
  );
}
