import { createSlice } from "@reduxjs/toolkit";
export const namespace = "orderList";

const initialState = {
  loading: true,
  error: null,
  data: null,
  count: 0,
};

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getList: (state) => ({
      ...state,
    }),
    getSuccess: (state, action) => ({
      ...state,
      error: null,
      loading: false,
      data: action.payload.data,
      count: action.payload.count,
    }),
    getError: (state, action) => ({
      ...state,
      data: [],
      count: 0,
      loading: false,
      error: action.payload,
    }),
    deleteOrder: (state) => ({
      ...state
    }),
    deleteOrderSuccess: (state, action) => ({
      ...state,
      data: state.data?.filter(item => item.id !== action.payload.id),
      count: state.count - 1
    })
  },
});

// Action creators are generated for each case reducer function
export const { getList, getSuccess, getError, deleteOrder, deleteOrderSuccess } = slice.actions;
// Selector
export const orderListSelector = (state) => state[namespace];
// Reducer
export const { reducer } = slice;

export { rootSagas as saga } from "./saga";
