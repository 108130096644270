import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from "@material-ui/core";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import InputIcon from "@material-ui/icons/Input";
import Logo from "src/components/Logo";
import PersonIcon from "@material-ui/icons/Person";
import { logOut as logOutRedux } from "src/state/auth/reducer";
import { COOKIE_TOKEN_NAME } from "src/utils/constant"

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage:
      "linear-gradient(#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff, #ffffff, #ffebce, #ffca7d, #ffa726)",
  },
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notifications] = useState([]);

  const logOut = () => {
    dispatch(logOutRedux({
      callback: (res) => {
        if (res.error) console.error(res.error)
        else {
          Cookies.remove(COOKIE_TOKEN_NAME);
          navigate("/login", { replace: true });
        }
      }
    }));
  };

  // handle Popover
  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(!anchorEl);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="primary">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon color="primary" />
            </Badge>
          </IconButton>
          <IconButton color="primary" onClick={handleClick}>
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <EmailOutlinedIcon color="primary" />
            </Badge>
          </IconButton>
          <Popover
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'center',
          // }}
          >
            <List>
              <ListItem button dense>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Nhàn nhắn tin cho bạn"
                  secondary="45 phút trước"
                ></ListItemText>
              </ListItem>
              <ListItem button dense>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Nhàn nhắn tin cho bạn"
                  secondary="45 phút trước"
                ></ListItemText>
              </ListItem>
              <ListItem button dense>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Nhàn nhắn tin cho bạn"
                  secondary="45 phút trước"
                ></ListItemText>
              </ListItem>
            </List>
          </Popover>
          <IconButton color="primary" onClick={logOut}>
            <InputIcon color="primary" />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="primary" onClick={onMobileNavOpen}>
            <MenuIcon color="primary" />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
