import _ from "lodash";
import Cookies from "js-cookie";
import { COOKIE_TOKEN_NAME } from "../../utils/constant";
import {
  takeEvery,
  put,
  all,
  call,
  fork,
  takeLatest,
} from "redux-saga/effects";
import {
  getUser,
  getUserSuccess,
  getUserError,
  logOut,
  forgotPassword,
  resetPassword,
  logoutSuccess,
} from "./reducer";
import mutationLogin, {
  logOutRedux,
  forgotPasswordRedux,
  resetPasswordRedux,
  loginWithToken,
} from "./query";
import callGraphqlApi from "src/utils/callGraphqlApi";

function* watchLogin({ payload: { payload, callback } }) {
  const resGetList = yield call(
    callGraphqlApi,
    "getUser",
    mutationLogin,
    payload,
    false
  );
  const errors = _.get(resGetList, "errors", null);
  if (callback) {
    if (errors) {
      yield put(getUserError(_.get(errors, "[0].message", null)));
      return callback({ error: _.get(errors, "[0].message", null) });
    }
    const data = _.get(resGetList, "data", null);
    if (data) {
      yield put(getUserSuccess(_.get(data, "login", {})));
      return callback({ data: _.get(data, "login", {}) });
    }
  }
}

function* watchLogOut({ payload: { callback } }) {
  const res = yield call(callGraphqlApi, "logOut", logOutRedux, null);
  const errors = _.get(res, "errors", null);
  if (errors) {
    const errorMsg = _.get(errors, "[0].message", null);
    return callback && callback({ error: errorMsg });
  }
  const data = _.get(res, "data.logout", null);
  if (data) {
    yield put(logoutSuccess());
    return callback && callback(true);
  }
}

function* watchForgotPassword({ payload: { payload, callback } }) {
  const res = yield call(
    callGraphqlApi,
    "forgotPassword",
    forgotPasswordRedux,
    payload,
    false
  );

  if (callback) {
    callback(res);
  }
}

function* watchResetPassword({ payload: { payload, callback } }) {
  const res = yield call(
    callGraphqlApi,
    "resetPassword",
    resetPasswordRedux,
    payload,
    false
  );

  if (callback) {
    callback(res);
  }
}

function* checkIsAuth() {
  const token = Cookies.get(COOKIE_TOKEN_NAME);
  if (!token) return;
  const res = yield call(callGraphqlApi, "loginWithToken", loginWithToken, {
    token,
  });
  const errors = _.get(res, "errors", null);
  if (errors) {
    Cookies.remove(COOKIE_TOKEN_NAME);
    return (window.location.href = `${window.location.origin}/login?redirect=${window.location.pathname}`);
  }
  const data = _.get(res, "data.loginWithToken", null);
  if (data) {
    yield put(getUserSuccess(data));
  }
}

export const rootSagas = function* rootSagas() {
  yield all([
    fork(checkIsAuth),
    takeEvery(getUser.type, watchLogin),
    takeLatest(logOut.type, watchLogOut),
    takeEvery(forgotPassword.type, watchForgotPassword),
    takeEvery(resetPassword.type, watchResetPassword),
  ]);
};
