export const userList = [
  {
    "id": 19,
    "avatar": "...",
    "email": " snowwright2000@gmail.com ",
    "name": "Nguỵ Tú Quỳnh",
    "type": "Thành viên"
  },
  {
    "id": 20,
    "avatar": "...",
    "email": " snowwright2000@gmail.com ",
    "name": "Nguỵ Tú Quỳnh",
    "type": "Thành viên"
  },
  {
    "id": 21,
    "avatar": "...",
    "email": " snowwright2000@gmail.com ",
    "name": "Nguỵ Tú Quỳnh",
    "type": "Thành viên"
  },
  {
    "id": 22,
    "avatar": "...",
    "email": " snowwright2000@gmail.com ",
    "name": "Nguỵ Tú Quỳnh",
    "type": "Thành viên"
  },
  
].map((item) => ({ 
  Index: item.id, 
  ["Avatar"]: item.avatar,/** eslint-disable no-useless-computed-key */
  ["Email"]: item.email,
  ["Họ và tên"]: item.name,
  ["Loại tài khoản"]: item.type,
}));

export const languageList = [
  {
    "id": 4,
    "code": "vi",
    "name": "Tiếng Việt",
    "priority": 1,
    "flag_icon": "https://dashboard.epispost.com//cms/images/assets/no-img_50x50.png",
    "vi_name": null,
    "created_at": null,
    "updated_at": "2020-07-21T13:22:43.000000Z",
    "deleted_at": null
  }
].map((item) => ({
  id: item.id,
  value: item.code,
  label: item.name
}));

export const statusList = [
  {
    id: '1',
    value: 'active',
    label: 'Active'
  },
  {
    id: '2',
    value: 'de_active',
    label: 'DeActive'
  }
]

export const currencyList = [{ id: 1, value: "VND", label: "Việt Nam Đồng" }, { id: 1, value: "AUD", label: "Đô la Úc" }]

export const shipServiceList = [
  {
    id: 1,
    value: "2",
    label: "Standard"
  },
  {
    id: 2,
    value: "1",
    label: "Express"
  }
]
