import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Page from "src/components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    backgroundColor: theme.palette.background.dark,
  },
}));

const INIT_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  passport_number: "",
  dob: "",
  phone: "",
  occupation: "dev",
  address: "",
  city: "",
  state: "",
  post_code: "",
  country: "vi",
};

const VerificationAccount = () => {
  const classes = useStyles();

  const handleSubmit = (values, callback) => {
  };

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Formik
          initialValues={INIT_VALUES}
          // TODO:
          // validationSchema={Yup.object().shape({
          //   email: Yup.string()
          //     .email("Must be a valid email")
          //     .max(255)
          //     .required("Email is required"),
          //   password: Yup.string()
          //     .max(255)
          //     .required("Password is required"),
          // })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} style={{}}>
              <Box mb={1}>
                <Typography color="textPrimary" variant="h2">
                  Verify your account
                </Typography>
              </Box>

              <Grid container direction="column">
                <Grid container spacing={3} item xs={6} sm={6}>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={touched.first_name && errors.first_name}
                      label="First Name"
                      margin="normal"
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.first_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(
                        touched.passport_number && errors.passport_number
                      )}
                      fullWidth
                      helperText={
                        touched.passport_number && errors.passport_number
                      }
                      label="Passport Number"
                      margin="normal"
                      name="passport_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.passport_number}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} item xs={6} sm={6}>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={touched.last_name && errors.last_name}
                      label="Last name"
                      margin="normal"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.last_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.dob && errors.dob)}
                      fullWidth
                      helperText={touched.dob && errors.dob}
                      label="Date of birth"
                      margin="normal"
                      name="dob"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.dob}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} item xs={6} sm={6}>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label="Phone"
                      margin="normal"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.phone}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} item xs={6} sm={6}>
                  <Grid item sm={12}>
                    <TextField
                      error={Boolean(touched.occupation && errors.occupation)}
                      fullWidth
                      helperText={touched.occupation && errors.occupation}
                      label="Occupation"
                      margin="normal"
                      name="occupation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.occupation}
                      variant="outlined"
                      select
                    >
                      <MenuItem value="dev">Dev</MenuItem>
                      <MenuItem value="ba">BA</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container spacing={3} item xs={6} sm={6}>
                  <Grid item sm={12}>
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      label="Address"
                      margin="normal"
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.address}
                      variant="outlined"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} item xs={6} sm={6}>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label="City"
                      margin="normal"
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.city}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.state && errors.state)}
                      fullWidth
                      helperText={touched.state && errors.state}
                      label="State"
                      margin="normal"
                      name="state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.state}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} item xs={6} sm={6}>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.post_code && errors.post_code)}
                      fullWidth
                      helperText={touched.post_code && errors.post_code}
                      label="Post code"
                      margin="normal"
                      name="post_code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.post_code}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      helperText={touched.country && errors.country}
                      label="Country"
                      margin="normal"
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.country}
                      variant="outlined"
                      select
                    >
                      <MenuItem value="vi">Viet Nam</MenuItem>
                      <MenuItem value="au">Australia</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                <Box mt={3}>
                  <Grid container justify="center" item xs={6} sm={6}>
                    <Grid item xs={6} sm={6}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Verify
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default VerificationAccount;
