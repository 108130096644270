import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography
} from '@material-ui/core';

import PageTitle from 'src/components/PageTitle';
import MyInput from 'src/components/Input';
import MySelect from 'src/components/Select';
import MyButton from 'src/components/Button';

import { orderStatusList, languageList } from './data';

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: 'left',
    padding: '1.25rem',
    boxShadow: '0 -3px 31px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 2%)',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '42%',
    },
  },
  typo: {
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '20px 0',
    },
  }
}));

export default function OrderStatusAdd() {
  const classes = useStyles();
  return (
    <>
      <PageTitle>Add Order Status</PageTitle>
      <Box p={2} mt={2} style={{ background: 'white', textAlign: 'center' }} borderRadius={2}>
        <Box classes={{ root: classes.form }}>
          <Typography variant="h6" classes={{ root: classes.typo }}>Ngôn ngữ</Typography>
          <MySelect
            label="Chọn ngôn ngữ"
            options={languageList}
            variant="outlined"
          />
          <Typography variant="h6" color="default" classes={{ root: classes.typo }}>Tên</Typography>
          <MyInput placeholder="" key="position" />
          <Typography variant="h6" color="default" classes={{ root: classes.typo }}>Trạng thái vận đơn của đối tác</Typography>
          <MySelect
            style={{ marginRight: '0 !important' }}
            label="---Chọn---"
            variant="outlined"
            options={orderStatusList.map((item) => ({
              id: item.id,
              value: item.Index,
              label: item.Title
            }))}
          />
        </Box>
        <Box mt={2}>
          <MyButton text="Lưu" color="success" style={{ marginRight: 15 }} />
          <NavLink to="/vcms/order-status/list">
            <MyButton text="Hủy" color="secondary" />
          </NavLink>
        </Box>
      </Box>
    </>
  );
}
