import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import DeleteIcon from "@material-ui/icons/Delete";
import Pagination from "@material-ui/lab/Pagination";
import MyButton from "src/components/Button";
// import Link from "@material-ui/core/Link";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// Dialog
import DialogDelete from "./Dialog/DialogDelete";

const useStyles = makeStyles({
  tableContainer: {
    width: "100%",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: 5,
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "&:: -webkit - scrollbar - thumb: hover": {
      background: "#555",
    },
  },
  myHead: {
    background: "#eee",
  },
  myCell: {
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  myCellAction: {
    border: "1px solid rgba(224, 224, 224, 1) !important",
    width: "50px !important",
    textAlign: "center",
  },
  myCellBtnAction: {
    // border: "1px solid rgba(224, 224, 224, 1) !important",
    // width: "50px !important",
    // textAlign: "center",
  },
  styleLink: {
    cursor: "pointer",
    color: '#ff9800'
  },
});

export default function MyTable({
  category,
  data,
  onDelete,
  page = 1,
  count = 10,
  onChange = () => {},
  isPrintPDF = false,
  status
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const handleDelete = (item) => () => {
    setOpenDelete(true);
    setSelectedItem(item);
  };

  const handleDeleteSubmit = () => {
    onDelete(selectedItem);
    setOpenDelete(false);
  };

  const handleDeleteCacel = () => {
    setOpenDelete(false);
    setSelectedItem();
  };

  return (
    <>
      {data && data.length > 0 && (
        <TableContainer classes={{ root: classes.tableContainer }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead classes={{ root: classes.myHead }}>
              <TableRow>
                {Object.keys(data[0]).map((item, id) => (
                  <TableCell
                    classes={{
                      root: id === 0 ? classes.myCellAction : classes.myCell,
                    }}
                    key={item}
                  >
                    {item}
                  </TableCell>
                ))}
                <TableCell classes={{ root: classes.myCellAction }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => (
                <TableRow key={item.id}>
                  {Object.values(item).map((it, id) => (
                    <>
                      {id === 1 ? (
                        <TableCell
                          classes={{
                            root:
                              id === 0 ? classes.myCellAction : classes.myCell,
                          }}
                          key={id}
                        >
                          <Link
                            to={`/vcms/${category}/edit/${item.Index}`}
                            className={classes.styleLink}
                          >
                            {it}
                          </Link>
                        </TableCell>
                      ) : (
                        <TableCell
                          classes={{
                            root:
                              id === 0 ? classes.myCellAction : classes.myCell,
                          }}
                          key={id}
                        >
                          {it}
                        </TableCell>
                      )}
                    </>
                  ))}
                  <TableCell classes={{ root: classes.myCellAction }}>
                    <div
                      style={{
                        width: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {status === "active" && <MyButton
                        variant="contained"
                        color="secondary"
                        onClick={handleDelete(item)}
                      >
                        <DeleteIcon />
                      </MyButton>}
                      {isPrintPDF && (
                        <MyButton
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            navigate(`/vcms/order-billing/${item.Index}`, {
                              replace: true,
                            });
                          }}
                          style={{ marginRight: 0 }}
                        >
                          <PictureAsPdfIcon />
                        </MyButton>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {count > 1 && (
            <Box mt={1} mb={1}>
              <Pagination
                variant="outlined"
                color="primary"
                shape="rounded"
                count={count}
                page={page}
                onChange={(event, value) => {
                  onChange(value);
                }}
              />
            </Box>
          )}
        </TableContainer>
      )}
      <DialogDelete
        open={openDelete}
        onSubmit={handleDeleteSubmit}
        onClose={handleDeleteCacel}
      />
    </>
  );
}
