import { createSlice } from "@reduxjs/toolkit";

export const namespace = "auth";

const initialState = {
  loading: true,
  error: null,
  data: null,
};

export const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getUser: (state) => ({
      ...state,
      // loading: true,
    }),
    getUserSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      data: action.payload.user,
    }),
    getUserError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: null,
    }),
    logOut: (state) => ({
      ...state,
    }),
    forgotPassword: (state) => ({
      ...state,
    }),
    resetPassword: (state) => ({
      ...state,
    }),
    logoutSuccess: (state) => ({
      ...state,
      data: null
    })
  },
});

// Action creators are generated for each case reducer function
// Selector
export const {
  getUser,
  getUserSuccess,
  getUserError,
  logOut,
  forgotPassword,
  resetPassword,
  logoutSuccess
} = slice.actions;
// Selector
export const authSelector = (state) => state[namespace];
// Reducer
export const { reducer } = slice;

export { rootSagas as saga } from "./saga";
