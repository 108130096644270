export default `query getUserList($limit: Int, $offset: Int, $keyword: String $status: StatusType $type: Int){
  getUserList(limit: $limit, offset: $offset, keyword: $keyword status: $status type: $type) {
    count
    data {
      id
      image
      email
      full_name
      type
    }
  }
}`;
