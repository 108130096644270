export const carrierList = [
  {
    "id": 1,
    "name": "Giao hàng nhanh",
    "deleted_at": null
  }
].map(item => ({
  Index: item.id,
  ["Tên"]: item.name
}))

export const languageList = [
  {
    "id": 4,
    "code": "vi",
    "name": "Tiếng Việt",
    "priority": 1,
    "flag_icon": "https://dashboard.epispost.com//cms/images/assets/no-img_50x50.png",
    "vi_name": null,
    "created_at": null,
    "updated_at": "2020-07-21T13:22:43.000000Z",
    "deleted_at": null
  }
].map((item) => ({
  id: item.id,
  value: item.code,
  label: item.name
}));

export const statusList = [
  {
    id: '1',
    value: 'active',
    label: 'Active'
  },
  {
    id: '2',
    value: 'de_active',
    label: 'DeActive'
  }
]