export default `query getList($limit: Int $offset: Int $keyword: String $language_code: String $status: StatusType) {
  getDistrictList(limit: $limit offset: $offset keyword: $keyword language_code: $language_code status: $status){
    count
    data {
      id
      ghn_id
      code
      description {
        name
      }
      province {
        description {
          name
        }
      }
    }
  }
}`;
